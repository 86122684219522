import { Fragment, useEffect, useState } from "react";
import SelectList from "@components/selectList";
import {
  InputRadio,
  InputNumber,
  InputSwitch,
} from "@components/formComponent";
import SearchableDropdown from "@components/SearchableDropdown";
import apiConfig from "../../../../api";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

const FilterBySKU = ({
  idKP = 1,
  typeRadio,
  typeFilter,
  onToggle,
  selectedData,
  setSelectedData,
  filterData,
  setFilterData,
  supertier,
  setSupertier,
  setBenefitData,
  setBenefitSuperTier,
  setBenefitKelipatan,
}) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const getData = async () => {
    const res = await apiConfig.get(
      `/api/admin/products?search=${search}&perPage=100`
    );
    const data = res?.data?.data?.length ? res?.data?.data : [];
    setData(transformData(data));
  };

  const transformData = (data) => {
    return data.map((item) => ({
      id: item.id,
      name: item.name,
      image: item?.images?.length ? item.images[0].image_url : null,
      relation_id: item.id,
      data: [
        { value: 0, type: "qty", is_active: 1, type_condition: "kelipatan" },
      ],
      ...item,
    }));
  };

  const handleAddTier = (itemId) => {
    const type_condition = filterData.group ?? "kelipatan";
    setSelectedData((prevData) =>
      prevData.map((item) => {
        if (item.id === itemId) {
          const newTier = {
            value: 0,
            type: item?.data?.length ? item.data[0].type : "",
            is_active: 1,
            type_condition,
          };
          return { ...item, data: [...item.data, newTier] };
        }
        return item;
      })
    );
  };

  const handleRemoveTier = (itemId, tierIdx) => {
    setSelectedData((prevData) =>
      prevData.map((item) => {
        if (item.id === itemId) {
          const filteredData = item.data.filter((tier, idx) => idx !== tierIdx);
          return { ...item, data: filteredData };
        }
        return item;
      })
    );
  };

  const handleToggleActive = (itemId, tierIdx) => {
    setSelectedData((prevData) =>
      prevData.map((item) => {
        if (item.id === itemId) {
          const updatedTiers = item.data.map((tier, idx) => {
            if (idx === tierIdx) {
              return { ...tier, is_active: tier.is_active === 1 ? 0 : 1 };
            }
            return tier;
          });
          return { ...item, data: updatedTiers };
        }
        return item;
      })
    );
  };
  const handleValueChange = (itemId, tierIdx, newValue) => {
    setSelectedData((prevData) =>
      prevData.map((item) => {
        if (item.id === itemId) {
          const updatedTiers = item.data.map((tier, idx) => {
            if (idx === tierIdx) {
              return { ...tier, value: newValue };
            }
            return tier;
          });
          return { ...item, data: updatedTiers };
        }
        return item;
      })
    );
  };
  const updateTipeSKUAndData = (newTipeSKU) => {
    setFilterData({ ...filterData, group: newTipeSKU });
    setSelectedData((prevData) =>
      prevData.map((item) => {
        if (newTipeSKU === "tiering") {
          return {
            ...item,
            data: [
              {
                value: 0,
                type: "qty",
                is_active: 1,
                type_condition: "tiering",
              },
              {
                value: 0,
                type: "qty",
                is_active: 1,
                type_condition: "tiering",
              },
            ],
          };
        } else if (newTipeSKU === "kelipatan") {
          return {
            ...item,
            data: [
              {
                value: 0,
                type: "qty",
                is_active: 1,
                type_condition: "kelipatan",
              },
            ],
          };
        }
        return item;
      })
    );
  };
  const handleTypeChange = (itemId, newType) => {
    setSelectedData((prevData) =>
      prevData.map((item) =>
        item.id === itemId
          ? {
              ...item,
              data: item.data.map((d) => ({
                ...d,
                type: newType,
              })),
            }
          : item
      )
    );
  };

  const handleSupertierStatusChange = (isActive) => {
    setSupertier((prevState) => ({
      ...prevState,
      is_active: isActive,
    }));
  };

  const handleRemoveSupertierBenefit = (isActive) => {
    setSupertier({ is_active: 0, data: [] });
    setBenefitData([]);
    setBenefitSuperTier(null);
    setBenefitKelipatan({
      type_benefit: "potongan-harga",
      type: "percentage",
      value: "0",
      maks_benefit: "0",
    });
  };
  const handleSupertierChange = (index, newValue) => {
    const updatedData = supertier.data.map((item, i) =>
      i === index ? { ...item, value: newValue } : item
    );

    setSupertier((prevState) => ({
      ...prevState,
      data: updatedData,
    }));
  };
  const updateSupertier = () => {
    // Mengambil jumlah tertinggi dari data yang aktif di selectedData
    const maxActiveDataLength = selectedData.reduce((max, item) => {
      const activeDataCount = item?.data?.length || 0;
      return Math.max(max, activeDataCount);
    }, 0);

    // Update supertier hanya dengan data yang aktif
    setSupertier((prevSupertier) => {
      const currentSupertierData = [...prevSupertier.data];

      // Membuat array baru berdasarkan jumlah tertinggi dari data aktif
      const newSupertierData = Array.from(
        { length: maxActiveDataLength },
        (_, index) => {
          return {
            value: currentSupertierData[index]?.value || 0,
            name: `Tier ${index + 1}`,
          };
        }
      );

      return {
        ...prevSupertier,
        data: newSupertierData,
      };
    });
  };
  const updateBenefitData = () => {
    const maxActiveDataLength = selectedData.reduce((max, item) => {
      const activeDataCount = item?.data?.length || 0;
      return Math.max(max, activeDataCount);
    }, 0);

    setBenefitData((prevBenefit) => {
      const currentSupertierData = [...prevBenefit];
      const newSupertierData = Array.from(
        { length: maxActiveDataLength },
        (_, index) => {
          return {
            value: currentSupertierData[index]?.value || 0,
            name: `Tier ${index + 1}`,
            type_benefit: "potongan-harga",
            type: "percentage",
            maks_benefit: "0",
          };
        }
      );
      return newSupertierData;
    });
  };
  const updateBenefitSuperTier = () => {
    setBenefitSuperTier({
      type_benefit: "potongan-harga",
      type: "percentage",
      value: "0",
      maks_benefit: "0",
    });
  };

  useEffect(() => {
    getData();
  }, [search]);

  useEffect(() => {
    if (!typeFilter) {
      if (filterData?.group === "tiering") {
        updateSupertier();
        updateBenefitData();
        updateBenefitSuperTier();
        setBenefitKelipatan(null);
      } else {
        handleRemoveSupertierBenefit();
      }
    }
  }, [selectedData, filterData]);

  return (
    <Fragment>
      <div className="bg-light my-2 px-2 border rounded-lg text-dark">
        <div className="d-flex flex-row justify-content-between my-2 p-2">
          <span className="font-weight-bold">SKU</span>
          <button className="border-0 bg-none" onClick={onToggle}>
            <i
              className="fa fa-trash"
              aria-hidden="true"
              style={{ color: "red" }}
            />
          </button>
        </div>
        <div className="d-flex flex-row justify-content-between my-2 px-1 py-2">
          <SelectList
            placeholder="Pilih"
            currValue={filterData?.value}
            onChange={(e) =>
              setFilterData({ ...filterData, value: e.target.value })
            }
            values={[
              { name: "All", val: "all" },
              { name: "Choose", val: "choose" },
              { name: "Except", val: "except" },
            ]}
          />
          <SearchableDropdown
            title="SKU"
            disabled={filterData?.value === "all"}
            items={data}
            selectedItems={selectedData}
            setSelectedItems={setSelectedData}
            searchTerm={search}
            setSearchTerm={setSearch}
            showSelected
          />
        </div>
        <div className="d-flex flex-row mx-1">
          <InputRadio
            name={`radioSKU-tiering-${typeRadio}-${idKP}`}
            label="Tiering"
            checked={filterData.group === "tiering"}
            onChange={() => {
              if (filterData.value !== "choose") {
                return true;
              }
              updateTipeSKUAndData("tiering");
            }}
          />
          <InputRadio
            name={`radioSKU-kelipatan-${typeRadio}-${idKP}`}
            label="Kelipatan"
            checked={filterData.group === "kelipatan"}
            onChange={() => updateTipeSKUAndData("kelipatan")}
          />
        </div>
        {selectedData.map((item) => (
          <div className="bg-white my-2 p-2 mx-2">
            <div className="d-flex flex-row justify-content-between my-2 mx-3">
              <h6>{item.name}</h6>
              <div className="d-flex flex-row mx-1">
                <InputRadio
                  name={`radioType-${typeRadio}-${item.id}-qty`}
                  label="Qty"
                  checked={item?.data?.length && item?.data[0]?.type === "qty"}
                  onChange={() => handleTypeChange(item.id, "qty")}
                />
                <InputRadio
                  name={`radioType-${typeRadio}-${item.id}-value`}
                  label="Value"
                  checked={
                    item?.data?.length && item?.data[0]?.type === "value"
                  }
                  onChange={() => handleTypeChange(item.id, "value")}
                />
              </div>
            </div>
            {filterData?.group === "tiering" ? (
              <>
                {!item?.data?.length
                  ? null
                  : item.data.map((tier, index) => (
                      <Fragment key={index + 1}>
                        <div className="mx-1 row">
                          <div className="col-2 d-flex align-items-center mb-3">
                            <input
                              type="checkbox"
                              name={`checkbox-${tier}-${typeRadio}`}
                              checked={tier?.is_active === 1}
                              style={{ transform: "scale(1.3)" }}
                              onChange={() =>
                                handleToggleActive(item.id, index)
                              }
                            />
                            <span className="ml-2">Tier {index + 1}</span>
                          </div>
                          <div className="d-flex col-10">
                            {tier.type === "qty" ? (
                              <div className={`w-100 ${index && "ml-2"}`}>
                                <InputNumber
                                  value={tier.value}
                                  onChange={(e) =>
                                    handleValueChange(
                                      item.id,
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className={`w-100 mb-3 rounded-lg ${
                                  !index && "ml-10"
                                }`}
                              >
                                <InputGroup
                                  style={{ marginLeft: "95px", width: "80%" }}
                                >
                                  <InputGroupAddon addonType="append">
                                    <InputGroupText>Rp</InputGroupText>
                                  </InputGroupAddon>
                                  <Input
                                    type="number"
                                    placeholder="0"
                                    min={0}
                                    value={tier.value}
                                    onChange={(e) =>
                                      handleValueChange(
                                        item.id,
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </InputGroup>
                              </div>
                            )}
                            {index ? (
                              <button
                                className="border-0 bg-white"
                                onClick={() => handleRemoveTier(item.id, index)}
                              >
                                <i
                                  className="mx-2 my-auto fa fa-times"
                                  aria-hidden="true"
                                  style={{ color: "red" }}
                                />
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </Fragment>
                    ))}
                <button
                  className="btn btn-outline-primary"
                  style={{
                    width: "95%",
                    marginLeft: "18px",
                    marginBottom: "10px",
                  }}
                  onClick={() => handleAddTier(item.id)}
                >
                  {"+"} Tambah Tier
                </button>
              </>
            ) : !item?.data?.length ? null : (
              item.data.map((kelipatan, index) => (
                <Fragment>
                  <div className="row mx-1">
                    <div className="col-2">
                      <span className="my-auto">Kelipatan</span>
                    </div>
                    <div className="col-10">
                      {kelipatan.type === "qty" ? (
                        <div className={`w-100 ${index && "ml-2"}`}>
                          <InputNumber
                            value={kelipatan.value}
                            onChange={(e) =>
                              handleValueChange(item.id, index, e.target.value)
                            }
                          />
                        </div>
                      ) : (
                        <div
                          className={`w-100 mb-3 rounded-lg ${
                            !index && "ml-10"
                          }`}
                        >
                          <InputGroup
                            style={{ marginLeft: "95px", width: "80%" }}
                          >
                            <InputGroupAddon addonType="append">
                              <InputGroupText>Rp</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="number"
                              placeholder="0"
                              min={0}
                              value={kelipatan.value}
                              onChange={(e) =>
                                handleValueChange(
                                  item.id,
                                  index,
                                  e.target.value
                                )
                              }
                            />
                          </InputGroup>
                        </div>
                      )}
                    </div>
                  </div>
                </Fragment>
              ))
            )}
          </div>
        ))}
        {filterData?.group === "kelipatan" ||
        typeFilter === "kondisi" ? null : (
          <Fragment>
            <div className="bg-white my-2 p-2 mx-2">
              <div className="d-flex my-1 ml-3 text-center ">
                <InputSwitch
                  value={supertier?.is_active}
                  onChange={(val) =>
                    handleSupertierStatusChange(val.target.checked)
                  }
                />
                <h6 className="mx-2 mt-1">SUPER TIER</h6>
              </div>
              {!supertier?.data?.length ? null : (
                <div className="mx-1 row">
                  {supertier.data.map(
                    (item, index) => (
                      <>
                        <div className="col-2 d-flex align-items-center mb-3">
                          <span>Qty {item.name}</span>
                        </div>
                        <div className="d-flex col-10">
                          <div className={`w-100`}>
                            <InputNumber
                              value={item.value}
                              onChange={(e) =>
                                handleSupertierChange(index, e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </>
                    )
                  )}
                </div>
              )}
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default FilterBySKU;
