import { Fragment } from "react";
import PropTypes from "prop-types";

const SwitchAndOr = ({ key, label, value, onChange, isRequired }) => {
  const active = value === "or" ? 1 : 0;

  function handleOnClick(selectedValue) {
    if (value !== selectedValue) {
      onChange(selectedValue);
    }
  }

  return (
    <Fragment key={key}>
      <div className="d-flex bg-light p-1 rounded-lg">
        <button
          className={`btn rounded-lg py-1 px-3 mx-1 ${
            active === 0 ? "btn-primary" : ""
          }`}
          onClick={() => handleOnClick("and")}
        >
          AND
        </button>
        <button
          className={`btn rounded-lg py-1 px-3 mx-1 ${
            active === 1 ? "btn-primary" : ""
          }`}
          onClick={() => handleOnClick("or")}
        >
          OR
        </button>
      </div>
    </Fragment>
  );
};

SwitchAndOr.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};

export default SwitchAndOr;
