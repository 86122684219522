import Promo from "../../pages/dashboard/promo";
//Basic Promo
import PromoDetail from "../../pages/dashboard/promo/basicPromo/detail";
import PromoAdd from "../../pages/dashboard/promo/basicPromo/add";
import PromoEdit from "../../pages/dashboard/promo/basicPromo/edit";
import PromoProducts from "../../pages/dashboard/promo/basicPromo/products";
import BasicPromo from "../../pages/dashboard/promo/basicPromo";
//Bundle
import BundlePromo from "../../pages/dashboard/promo/bundlePromo";
import AddBundle from "../../pages/dashboard/promo/bundlePromo/add";
import EditBundle from "../../pages/dashboard/promo/bundlePromo/edit";
//Harga coret
import HargaCoret from "../../pages/dashboard/promo/hargaCoret";
import DetailHargaCoret from "pages/dashboard/promo/hargaCoret/detail";
//Free Barang
import FreeBarang from "../../pages/dashboard/promo/freeBarang";
import AddFreeBarang from "pages/dashboard/promo/freeBarang/add";
import EditFreeBarang from "pages/dashboard/promo/freeBarang/edit";
//New Promotion
import NewPromotion from "pages/dashboard/newPromotion";
import NewPromotionAdd from "pages/dashboard/newPromotion/add";
import NewPromoSelectType from "pages/dashboard/newPromotion/selectPromo";
import NewPromotionEdit from "pages/dashboard/newPromotion/edit";
import DetailPromotion from "pages/dashboard/newPromotion/detailPromotion";

export const RoutePromo = [
  {
    path: `/dashboard/promo`,
    Component: Promo,
  },
  {
    path: `/dashboard/promo/basic`,
    Component: BasicPromo,
  },
  {
    path: `/dashboard/promo/basic/add`,
    Component: PromoAdd,
  },
  {
    path: `/dashboard/promo/basic/details/:id`,
    Component: PromoDetail,
  },
  {
    path: `/dashboard/promo/basic/edit/:id`,
    Component: PromoEdit,
  },
  {
    path: `/dashboard/promo/basic/products/:id`,
    Component: PromoProducts,
  },
  {
    path: `/dashboard/promo/bundle`,
    Component: BundlePromo,
  },
  {
    path: `/dashboard/promo/bundle/add`,
    Component: AddBundle,
  },
  {
    path: `/dashboard/promo/bundle/edit/:id`,
    Component: EditBundle,
  },
  {
    path: `/dashboard/promo/harga-coret`,
    Component: HargaCoret,
  },
  {
    path: `/dashboard/promo/harga-coret/:id`,
    Component: DetailHargaCoret,
  },
  {
    path: `/dashboard/promo/promo-free-barang`,
    Component: FreeBarang,
  },
  {
    path: `/dashboard/promo/promo-free-barang/add`,
    Component: AddFreeBarang,
  },
  {
    path: `/dashboard/promo/promo-free-barang/edit/:id`,
    Component: EditFreeBarang,
  },
  {
    path: `/dashboard/promotion`,
    Component: NewPromotion,
  },
  {
    path: `/dashboard/promotion/add`,
    // Component: NewPromotionAdd,
    Component: NewPromoSelectType,
  },
  {
    path: `/dashboard/promotion/edit/:id`,
    Component: NewPromotionEdit,
  },
  {
    path: `/dashboard/promotion/detail-promotion/:id`,
    Component: DetailPromotion,
  },
];
