import { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

//component
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from "@layout/breadcrumb";
import AddKondisiCustomer from "./addKondisiCustomer";
import AddKondisiProduct from "./addKondisiProduct";
import BenefitPromo from "../component/BenefitPromo";
import PreviewInformation from "./PreviewInformation";
import PromoInformation from "./PromoInformation";
import { ModalDialog } from "@components/modal/ModalDialog";
import ModalContent from "@components/modal/ModalContent";
import moment from "moment";
import { createNewPromo } from "@action/promoAction";
import { useEffect } from "react";

const NewPromotionAdd = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [namaPromo, setNamaPromo] = useState("");
  const [kodePromo, setKodePromo] = useState("");
  const [kuotaPromo, setKuotaPromo] = useState("");
  const [tipePromo, setTipePromo] = useState("other");
  const [image, setImage] = useState(null);
  const [formStartDate, setStartDate] = useState("");
  const [formEndDate, setEndDate] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [benefitData, setBenefitData] = useState([]);
  const [benefitSuperTier, setBenefitSuperTier] = useState(null);
  const [benefitKelipatan, setBenefitKelipatan] = useState(null);
  const [exitDialog, setExitDialog] = useState(false);

  //** -------- Product -------- */
  const [productOn, setProductOn] = useState(true);
  const [productCondition, setProductCondition] = useState("and");
  const [productConditionKC, setProductConditionKC] = useState("and");
  const [isAddKondisiProduct, setAddKondisiProduct] = useState([]);
  //manufactur
  const [isManufaktur, setManufaktur] = useState(false);
  const [manufakturData, setManufakturData] = useState({
    value: "",
    type: "manufactur",
  });
  const [selectedManufactur, setSelectedManufactur] = useState([]);
  // Kategori
  const [isKategori, setKategori] = useState(false);
  const [kategoriData, setKategoriData] = useState({
    value: "",
    type: "kategori",
  });
  const [selectedKategori, setSelectedKategori] = useState([]);
  //SKU
  const [isSKU, setSKU] = useState(false);
  const [skuData, setSkuData] = useState({
    value: "",
    type: "sku",
    group: "kelipatan",
  });
  const [selectedSKU, setSelectedSKU] = useState([]);
  // Supertier
  const [supertier, setSupertier] = useState({ is_active: 0, data: [] });
  //** -------- End Product -------- */

  //** -------- Customer -------- */
  const [customerOn, setCustomerOn] = useState(true);
  const [customerCondition, setCustomerCondition] = useState("and");
  const [customerConditionKC, setCustomerConditionKC] = useState("and");
  const [isAddKondisiCustomer, setAddKondisiCustomer] = useState([]);
  //customer
  const [isCustomer, setCustomer] = useState(false);
  const [customerData, setCustomerData] = useState({
    value: "",
    type: "customers",
  });
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  //sales
  const [isSales, setSales] = useState(false);
  const [salesData, setSalesData] = useState({
    value: "",
    type: "sales",
  });
  const [selectedSales, setSelectedSales] = useState([]);
  //WL area
  const [isWLarea, setWLarea] = useState(false);
  const [wlAreaData, setWlAreaData] = useState({
    value: "",
    type: "wl_area",
  });
  const [selectedWlArea, setSelectedWlArea] = useState([]);
  //Jenis Customer
  const [isUser, setUser] = useState(false);
  const [userData, setUserData] = useState({
    value: "",
    type: "jenis_customer",
  });
  const [selectedUser, setSelectedUser] = useState([]);
  //** -------- End Customer -------- */

  const onSubmit = async () => {
    const customerLevel1 = {
      level: 1,
      condition: customerCondition,
      data: [
        {
          ...customerData,
          items: filterDataId(selectedCustomer),
        },
        {
          ...salesData,
          items: filterDataId(selectedSales),
        },
        {
          ...wlAreaData,
          items: filterDataId(selectedWlArea),
        },
        {
          ...userData,
          items: filterDataId(selectedUser),
        },
      ],
    };

    const dataCustomerLvl2 = isAddKondisiCustomer.map((item) => {
      return {
        ...item,
        data: item.data.length
          ? item.data.map((x) => {
              return {
                ...x,
                items: filterDataId(x.items),
              };
            })
          : [],
      };
    });

    const customerLevel2 = filterDataAddConditionLvl2(dataCustomerLvl2);

    const superTierData = filterDataQty(supertier.data);
    const productLevel1 = {
      level: 1,
      condition: productCondition,
      data: [
        {
          ...manufakturData,
          items: filterDataId(selectedManufactur),
        },
        {
          ...kategoriData,
          items: filterDataId(selectedKategori),
        },
        {
          ...skuData,
          items: filterDataSku(selectedSKU),
          super_tier: supertier.is_active ? [...superTierData] : [],
        },
      ],
    };

    const dataProductLvl2 = isAddKondisiProduct.map((item) => {
      return {
        ...item,
        data: item?.data?.length
          ? item.data.map((x) => {
              return {
                ...x,
                items:
                  x.type === "sku"
                    ? filterDataSku(x.items)
                    : filterDataId(x.items),
              };
            })
          : [],
      };
    });

    const productLevel2 = filterDataAddConditionLvl2(dataProductLvl2);

    const benefitST = [{ ...benefitSuperTier, is_super_tier: 1 }];
    let benefit = benefitSuperTier
      ? [...benefitData, ...benefitST]
      : benefitData;
    benefit = benefitKelipatan ? [...benefit, benefitKelipatan] : benefit;
    benefit = filterBenefit(benefit);

    const customerLvlData = {
      data: customerOn
        ? [deleteEmptyData(customerLevel1), ...customerLevel2]
        : [],
      condition: customerCondition,
      type: "customers",
      is_active: customerOn,
    };
    const productLvlData = {
      data: productOn ? [deleteEmptyData(productLevel1), ...productLevel2] : [],
      condition: productCondition,
      type: "products",
      is_active: productOn,
    };
    const data = {
      data: [customerLvlData, productLvlData],
      benefit,
    };

    const params = {
      nama: namaPromo,
      kode: kodePromo,
      kuota: kuotaPromo,
      banner: image,
      deskripsi: deskripsi,
      start_at: formStartDate ? moment(formStartDate).format("YYYY-MM-DD") : "",
      end_at: formEndDate ? moment(formEndDate).format("YYYY-MM-DD") : "",
      items_condition: JSON.stringify(data),
    };
    console.log("onotrak data", data);
    console.log("onotrak params", params);

    const formData = new FormData();
    formData.append("nama", namaPromo);
    formData.append("kode", kodePromo);
    formData.append("kuota", kuotaPromo);
    formData.append("banner", image?.file ?? "");
    formData.append("deskripsi", deskripsi);
    formData.append(
      "start_at",
      formStartDate ? moment(formStartDate).format("YYYY-MM-DD") : ""
    );
    formData.append(
      "end_at",
      formEndDate ? moment(formEndDate).format("YYYY-MM-DD") : ""
    );
    formData.append("items_condition", JSON.stringify(data));

    dispatch(createNewPromo(formData)).then((res) => {
      console.log("onotrak res add promo", res);
      if (res.status !== 200) return false;
      history.push("/dashboard/promotion#tab=1");
    });
  };
  const filterBenefit = (dataArray) => {
    if (!dataArray?.length) return [];
    return dataArray.map((obj) => ({
      type_benefit: obj.type_benefit || "",
      type: obj.type || "",
      value: obj.value || "",
      maks_benefit: obj.maks_benefit || "",
      is_super_tier: obj.is_super_tier || "0",
    }));
  };
  const filterDataId = (dataArray) => {
    if (!dataArray?.length) return [];
    return dataArray.map((obj) => obj?.id?.toString());
  };
  const filterDataQty = (dataArray) => {
    if (!dataArray?.length) return [];
    return dataArray.map((obj) => ({
      qty: obj.value || "",
    }));
  };
  const filterDataAddCondition = (dataArray) => {
    if (!dataArray?.length) return [];
    return dataArray.reduce((acc, curr) => {
      if (Array.isArray(curr.data)) {
        return acc.concat(curr.data);
      }
      return acc;
    }, []);
  };
  const filterDataAddConditionLvl2 = (dataArray) => {
    if (!dataArray?.length) return [];
    return dataArray.map((obj, idx) => ({
      level: idx + 2,
      condition: obj.condition || "and",
      data: obj.data,
    }));
  };
  const filterDataSku = (dataArray) => {
    if (!dataArray?.length) return [];
    return dataArray.map((obj) => ({
      relation_id: obj.relation_id || "",
      data: obj?.data?.length ? obj.data : [],
    }));
  };

  const deleteEmptyData = (data) => {
    return {
      ...data,
      data: data.data.filter((item) => item.value !== ""),
    };
  };

  const addBenefitData = () => {
    setBenefitData([
      {
        type_benefit: "potongan-harga",
        type: "percentage",
        value: "0",
        maks_benefit: "0",
      },
    ]);
  };

  const deleteDefaultBenefitData = () => {
    const data = benefitData.filter((item) => item.hasOwnProperty("name"));
    setBenefitData(data);
  };

  useEffect(() => {
    if (
      (isCustomer ||
        isSales ||
        isWLarea ||
        isUser ||
        isManufaktur ||
        isAddKondisiCustomer.length ||
        isAddKondisiProduct.length ||
        isManufaktur ||
        isKategori) &&
      !isSKU
    ) {
      addBenefitData();
    } else {
      deleteDefaultBenefitData();
    }
  }, [isCustomer, isSales, isWLarea, isUser, isManufaktur, isKategori, isSKU]);

  return (
    <Fragment>
      {/* <Breadcrumb
        parent="Promotion"
        title="Add New Promotion"
        urlParent={"/dashboard/promotion"}
      /> */}
      {/* <Container fluid> */}
        <Row>
          <Col className="mb-sm-2" sm={12} lg={8}>
            <PromoInformation
              namaPromo={namaPromo}
              setNamaPromo={setNamaPromo}
              kodePromo={kodePromo}
              setKodePromo={setKodePromo}
              kuotaPromo={kuotaPromo}
              setKuotaPromo={setKuotaPromo}
              image={image}
              setImage={setImage}
              deskripsi={deskripsi}
              setDeskripsi={setDeskripsi}
              tipePromo={tipePromo}
              setTipePromo={setTipePromo}
              formStartDate={formStartDate}
              setStartDate={setStartDate}
              formEndDate={formEndDate}
              setEndDate={setEndDate}
            />

            <div className="d-flex flex-column bg-white shadow-sm my-3 p-3 border rounded-lg">
              <h4>Kondisi</h4>
              <div className="p-2">
                <AddKondisiCustomer
                  customerOn={customerOn}
                  setCustomerOn={setCustomerOn}
                  customerCondition={customerCondition}
                  setCustomerCondition={setCustomerCondition}
                  isAddKondisi={isAddKondisiCustomer}
                  setAddKondisi={setAddKondisiCustomer}
                  isCustomer={isCustomer}
                  setCustomer={setCustomer}
                  customerData={customerData}
                  setCustomerData={setCustomerData}
                  selectedCustomer={selectedCustomer}
                  setSelectedCustomer={setSelectedCustomer}
                  isSales={isSales}
                  setSales={setSales}
                  salesData={salesData}
                  setSalesData={setSalesData}
                  selectedSales={selectedSales}
                  setSelectedSales={setSelectedSales}
                  isWLarea={isWLarea}
                  setWLarea={setWLarea}
                  wlAreaData={wlAreaData}
                  setWlAreaData={setWlAreaData}
                  selectedWlArea={selectedWlArea}
                  setSelectedWlArea={setSelectedWlArea}
                  isUser={isUser}
                  setUser={setUser}
                  userData={userData}
                  setUserData={setUserData}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                  customerConditionKC={customerConditionKC}
                  setCustomerConditionKC={setCustomerConditionKC}
                />
                <AddKondisiProduct
                  productOn={productOn}
                  setProductOn={setProductOn}
                  productCondition={productCondition}
                  setProductCondition={setProductCondition}
                  isAddKondisi={isAddKondisiProduct}
                  setAddKondisi={setAddKondisiProduct}
                  isManufaktur={isManufaktur}
                  setManufaktur={setManufaktur}
                  manufakturData={manufakturData}
                  setManufakturData={setManufakturData}
                  selectedManufactur={selectedManufactur}
                  setSelectedManufactur={setSelectedManufactur}
                  isKategori={isKategori}
                  setKategori={setKategori}
                  kategoriData={kategoriData}
                  setKategoriData={setKategoriData}
                  selectedKategori={selectedKategori}
                  setSelectedKategori={setSelectedKategori}
                  isSKU={isSKU}
                  setSKU={setSKU}
                  skuData={skuData}
                  setSkuData={setSkuData}
                  selectedSKU={selectedSKU}
                  setSelectedSKU={setSelectedSKU}
                  supertier={supertier}
                  setSupertier={setSupertier}
                  setBenefitData={setBenefitData}
                  setBenefitSuperTier={setBenefitSuperTier}
                  setBenefitKelipatan={setBenefitKelipatan}
                  productConditionKC={productConditionKC}
                  setProductConditionKC={setProductConditionKC}
                  isBenefit={
                    isCustomer ||
                    isSales ||
                    isWLarea ||
                    isUser ||
                    isManufaktur ||
                    isKategori
                  }
                />
              </div>
            </div>
            <BenefitPromo
              benefitData={benefitData}
              setBenefitData={setBenefitData}
              benefitSuperTier={benefitSuperTier}
              setBenefitSuperTier={setBenefitSuperTier}
              benefitKelipatan={benefitKelipatan}
              setBenefitKelipatan={setBenefitKelipatan}
              supertier={supertier}
            />
          </Col>
          <PreviewInformation
            //** Promo Information */
            namaPromo={namaPromo}
            kodePromo={kodePromo}
            tipePromo={tipePromo}
            formStartDate={formStartDate}
            formEndDate={formEndDate}
            //** End */

            //** Customer */
            isCustomer={isCustomer}
            selectedCustomer={selectedCustomer}
            isSales={isSales}
            selectedSales={selectedSales}
            isWLarea={isWLarea}
            selectedWlArea={selectedWlArea}
            isUser={isUser}
            selectedUser={selectedUser}
            //** End */

            //** Product */
            isManufaktur={isManufaktur}
            selectedManufactur={selectedManufactur}
            isKategori={isKategori}
            selectedKategori={selectedKategori}
            isSKU={isSKU}
            selectedSKU={selectedSKU}
            supertier={supertier?.data?.length ? supertier.data : []}
            superTierIsActive={supertier?.is_active}
            skuData={skuData}
            //** End */

            // Benefit
            benefitData={benefitData}
            benefitSuperTier={benefitSuperTier}
            benefitKelipatan={benefitKelipatan}
            // Button
            setExitDialog={setExitDialog}
            onSubmit={onSubmit}
          />
        </Row>

        <Fragment>
          <ModalDialog
            open={exitDialog}
            footer={false}
            title={"Unsaved Changes"}
            titleFontSize={18}
            backdropClose={true}
            onClose={() => {
              setExitDialog(false);
            }}
            maxWidth={384}
          >
            <ModalContent
              onClose={() => {
                setExitDialog(false);
              }}
              onSubmit={() => history.push("/dashboard/promotion/")}
              description={`You have unsaved changes. If you go back now, you will lose all your changes.`}
              descClose={"Cancel"}
              descSubmit={"Back To Promo List"}
            />
          </ModalDialog>
        </Fragment>
      {/* </Container> */}
    </Fragment>
  );
};

export default NewPromotionAdd;
