import { Fragment, useState } from "react";

import { InputSwitch } from "@components/formComponent";
import SwitchAndOr from "../component/SwitchAndOr";
import PopUpKondisi from "../component/PopUpKondisi";
import FilterByCustomer from "../component/filterByCustomer";
import FilterBySales from "../component/filterBySales";
import FilterByWLarea from "../component/filterByWLarea";
import FilterByUser from "../component/filterByUser";
import FilterByKondisiCustomer from "../component/filterByKondisiCustomer";

const AddKondisiCustomer = ({
  customerOn,
  setCustomerOn,
  customerCondition,
  setCustomerCondition,
  isAddKondisi,
  setAddKondisi,
  isCustomer,
  setCustomer,
  customerData,
  setCustomerData,
  selectedCustomer,
  setSelectedCustomer,
  isSales,
  setSales,
  salesData,
  setSalesData,
  selectedSales,
  setSelectedSales,
  isWLarea,
  setWLarea,
  wlAreaData,
  setWlAreaData,
  selectedWlArea,
  setSelectedWlArea,
  isUser,
  setUser,
  userData,
  setUserData,
  selectedUser,
  setSelectedUser,

  customerConditionKC,
  setCustomerConditionKC,
}) => {
  const [openModal, setModal] = useState(false);

  function handleAddFilter(val) {
    switch (val) {
      case "customer":
        setCustomer(true);
        break;
      case "sales":
        setSales(true);
        break;
      case "wlarea":
        setWLarea(true);
        break;
      case "user":
        setUser(true);
        break;
      default:
        break;
    }
  }

  function handleAddKondisi() {
    setModal(false);
    setAddKondisi([
      ...isAddKondisi,
      {
        name: `${
          isAddKondisi?.length
            ? parseInt(isAddKondisi[isAddKondisi.length - 1].name) + 1
            : 1
        }`,
      },
    ]);
  }

  const handleRemoveCondition = (itemName) => {
    setAddKondisi((prevData) =>
      prevData.filter((item) => item.name !== itemName)
    );
  };

  return (
    <Fragment>
      <div className="my-3 p-3 border rounded-lg">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex my-1">
            <div className="my-auto">
              <InputSwitch
                value={customerOn}
                onChange={(val) => setCustomerOn(val.target.checked)}
              />
            </div>
            <span className="mx-2 font-weight-bold h5">Customer</span>
          </div>
          <div className="d-flex">
            <SwitchAndOr
							key={'addKondisiCustomer'}
              value={customerCondition}
              onChange={(val) => setCustomerCondition(val)}
            />
            <button
              type="button"
              className="ml-2 btn btn-primary"
              onClick={() => setModal(!openModal)}
            >
              {"+"} Tambah Kondisi
            </button>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center my-2 p-3">
          {isCustomer && (
            <FilterByCustomer
              onToggle={() => {
                setCustomerData({ value: "", type: "customers" });
                setSelectedCustomer([]);
                setCustomer(!isCustomer);
              }}
              selectedData={selectedCustomer}
              setSelectedData={setSelectedCustomer}
              filterData={customerData}
              setFilterData={setCustomerData}
            />
          )}
          {isSales && (
            <FilterBySales
              onToggle={() => {
                setSalesData({ value: "", type: "sales" });
                setSelectedSales([]);
                setSales(!isSales);
              }}
              selectedData={selectedSales}
              setSelectedData={setSelectedSales}
              filterData={salesData}
              setFilterData={setSalesData}
            />
          )}
          {isWLarea && (
            <FilterByWLarea
              onToggle={() => {
                setWlAreaData({ value: "", type: "wl_area" });
                setSelectedWlArea([]);
                setWLarea(!isWLarea);
              }}
              selectedData={selectedWlArea}
              setSelectedData={setSelectedWlArea}
              filterData={wlAreaData}
              setFilterData={setWlAreaData}
            />
          )}
          {isUser && (
            <FilterByUser
              onToggle={() => {
                setWlAreaData({ value: "", type: "jenis_customer" });
                setSelectedWlArea([]);
                setUser(!isUser);
              }}
              selectedData={selectedUser}
              setSelectedData={setSelectedUser}
              filterData={userData}
              setFilterData={setUserData}
            />
          )}

          {!isAddKondisi?.length
            ? null
            : isAddKondisi.map((item) => (
                <FilterByKondisiCustomer
                  kondisi="customer"
                  item={item}
                  onDeleteFilter={() => handleRemoveCondition(item.name)}
                  customerCondition={customerConditionKC}
                  setCustomerCondition={setCustomerConditionKC}
                  isAddKondisi={isAddKondisi}
                  setAddKondisi={setAddKondisi}
                />
              ))}

          {!isCustomer &&
          !isSales &&
          !isWLarea &&
          !isUser &&
          !isAddKondisi?.length ? (
            <div className="bg-light p-3 rounded-lg text-center text-dark">
              <span>Silahkan untuk menambahkan filter</span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <PopUpKondisi
        kondisi="customer"
        isOpen={openModal}
        onToggle={() => setModal(!openModal)}
        onAddFilter={handleAddFilter}
        onAddKondisi={handleAddKondisi}
      />
    </Fragment>
  );
};

export default AddKondisiCustomer;
