import { Fragment } from "react";
import { Modal } from "reactstrap";

import filtermanufaktur from "@assets/images/filter__manufaktur.svg";
import filtersku from "@assets/images/filter__sku.svg";
import filterkategori from "@assets/images/filter__kategori.svg";

const PopUpFilterProduct = ({ isOpen, onToggle, onAddFilter, disableSKU }) => {
  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={onToggle} centered size="lg">
        <div className="d-flex flex-row justify-content-between p-3">
          <h5 className="my-2 font-weight-bolder">Pilih Filter</h5>
          <button
            type="button"
            onClick={onToggle}
            className="border-0 bg-white p-auto"
          >
            <i className="fa-solid fa-x"></i>
          </button>
        </div>
        <div className="px-3 py-2 container-fluid">
          <div className="row">
            <div className="col">
              <button
                type="button"
                className="d-flex flex-row bg-white my-3 p-2 border rounded-lg w-100"
                onClick={() => onAddFilter("manufactur")}
              >
                <img
                  src={filtermanufaktur}
                  alt="filter by customer"
                  className="bg-light mx-1 my-auto p-2 rounded-circle"
                />
                <div className="d-flex flex-column mx-1 text-left">
                  <span className="font-weight-bold">Manufaktur</span>
                  <span>Filter berdasarkan manufaktur</span>
                </div>
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                className="d-flex flex-row bg-white my-3 p-2 border rounded-lg w-100"
                onClick={() => onAddFilter("kategori")}
              >
                <img
                  src={filterkategori}
                  alt="filter by sales"
                  className="bg-light mx-1 my-auto p-2 rounded-circle"
                />
                <div className="d-flex flex-column mx-1 text-left">
                  <span className="font-weight-bold">Kategori</span>
                  <span>Filter berdasarkan kategori</span>
                </div>
              </button>
            </div>
            <div className="w-100"></div>
            {!disableSKU && (
              <div className="col">
                <button
                  type="button"
                  className="d-flex flex-row bg-white my-3 p-2 border rounded-lg w-100"
                  onClick={() => onAddFilter("sku")}
                >
                  <img
                    src={filtersku}
                    alt="filter by wil area"
                    className="bg-light mx-1 my-auto p-2 rounded-circle"
                  />
                  <div className="d-flex flex-column mx-1 text-left">
                    <span className="font-weight-bold">SKU</span>
                    <span>Filter berdasarkan SKU</span>
                  </div>
                </button>
              </div>
            )}
            <div className="col"></div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default PopUpFilterProduct;
