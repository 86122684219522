import { Fragment, useEffect, useState } from "react";
import SelectList from "@components/selectList";
import SearchableDropdown from "@components/SearchableDropdown";
import apiConfig from "../../../../api";

const FilterByKategori = ({
  onToggle,
  selectedData,
  setSelectedData,
  filterData,
  setFilterData,
}) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const getData = async () => {
    const res = await apiConfig.get(
      `/api/admin/category?search=${search}&perPage=100`
    );
    const data = res?.data?.data?.data?.length ? res?.data?.data?.data : [];
    setData(transformData(data));
  };

  const transformData = (data) => {
    return data.map((item) => ({
      id: item.id,
      name: item.name,
      image: item.file_url,
      ...item,
    }));
  };

  useEffect(() => {
    getData();
  }, [search]);

  return (
    <Fragment>
      <div className="bg-light my-2 border rounded-lg text-dark">
        <div className="d-flex flex-row justify-content-between my-2 p-2">
          <span className="font-weight-bold">Kategori</span>
          <button className="border-0 bg-none" onClick={onToggle}>
            <i
              className="fa fa-trash"
              aria-hidden="true"
              style={{ color: "red" }}
            ></i>
          </button>
        </div>
        <div className="d-flex flex-row justify-content-between my-2 px-1 py-2">
          <SelectList
            placeholder="Pilih"
            currValue={filterData?.value}
            onChange={(e) =>
              setFilterData({ ...filterData, value: e.target.value })
            }
            values={[
              { name: "All", val: "all" },
              { name: "Choose", val: "choose" },
              { name: "Except", val: "except" },
            ]}
          />
          <SearchableDropdown
            title="Kategori"
            disabled={filterData?.value === "all"}
            items={data}
            selectedItems={selectedData}
            setSelectedItems={setSelectedData}
            searchTerm={search}
            setSearchTerm={setSearch}
            showSelected
          />
        </div>
      </div>
    </Fragment>
  );
};

export default FilterByKategori;
