import {
  InputArea,
  InputDateRange,
  InputImage,
  InputNumber,
  InputRadio,
  InputText,
} from "@components/formComponent";
import React from "react";
import { Col, Row } from "reactstrap";

const PromoInformation = ({
  namaPromo,
  setNamaPromo,
  kodePromo,
  setKodePromo,
  kuotaPromo,
  setKuotaPromo,
  image,
  setImage,
  deskripsi,
  setDeskripsi,
  tipePromo,
  setTipePromo,
  formStartDate,
  setStartDate,
  formEndDate,
  setEndDate,
}) => {
  return (
    <div className="bg-white shadow-sm mb-3 p-3 px-4 border rounded-lg">
      <h5>Informasi Promo</h5>
      <InputText
        labelWidth={3}
        inputWidth={9}
        label="Nama Promo"
        value={namaPromo}
        onChange={(e) => setNamaPromo(e.target.value)}
      />
      <InputText
        labelWidth={3}
        inputWidth={9}
        label="Kode Promo"
        value={kodePromo}
        onChange={(e) => setKodePromo(e.target.value)}
      />
      <InputNumber
        labelWidth={3}
        inputWidth={9}
        label="Kuota Promo"
        value={kuotaPromo}
        onChange={(e) => setKuotaPromo(e.target.value)}
      />
      <InputImage
        labelWidth={3}
        inputWidth={9}
        label="Banner Promo"
        value={image ? [image] : []}
        onChange={(images) => setImage(images[images.length - 1])}
      />
      <InputArea
        labelWidth={3}
        inputWidth={9}
        label="Deskripsi"
        value={deskripsi}
        onChange={(e) => setDeskripsi(e.target.value)}
      />
      <Row>
        {/* <Col
          className="d-flex flex-lg-column flex-xl-row justify-content-between"
          sm={2}
          lg={3}
        >
          <InputRadio
            name="radioPromo-type-other"
            label="Other"
            checked={tipePromo === "other" ? true : false}
            onChange={() => setTipePromo("other")}
          />
          <InputRadio
            name="radioPromo-type-flashsale"
            label="Flashsale"
            checked={tipePromo === "flashsale" ? true : false}
            onChange={() => setTipePromo("flashsale")}
          />
        </Col> */}
        <Col sm={9}>
          <InputDateRange
            inputWidth={12}
            className="w-100"
            startDate={formStartDate}
            endDate={formEndDate}
            onChangeStartDate={(val) => setStartDate(val)}
            onChangeEndDate={(val) => setEndDate(val)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PromoInformation;
