import { Fragment, useState } from "react";
import { Modal } from "reactstrap";
import PopUpFilter from "./PopUpFilter";
import PopUpFilterProduct from "./PopUpFilterProduct";

const PopUpKondisi = ({
  kondisi,
  isOpen,
  onToggle,
  onAddKondisi,
  onAddFilter,
  disableSKU,
}) => {
  const [isFilter, setFilter] = useState(false);

  function handleAddFilter(val) {
    setFilter(false);
    onAddFilter(val);
    onToggle();
  }

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={onToggle} centered size="lg" className="">
        <div>
          <div className="d-flex flex-row justify-content-between mx-4 mt-4 px-1 pt-2">
            <h5 className="font-weight-bolder">Tambah Kondisi</h5>
            <button
              type="button"
              onClick={onToggle}
              className="border-0 bg-white p-auto"
            >
              <i className="fa-solid fa-x"></i>
            </button>
          </div>
          <div className="mx-3 mb-3 px-3 pb-1">
            <button
              type="button"
              className="d-flex flex-row justify-content-between bg-white my-3 p-2 border rounded-lg w-100"
              onClick={() => setFilter(true)}
            >
              <div className="d-flex flex-column text-left">
                <span className="font-weight-bold">Kondisi</span>
                <span style={{ color: "#687083" }}>
                  Menambahkan Filter tunggal
                </span>
              </div>
              <div className="my-auto pr-1">
                <i className="fa-arrow-right fa" aria-hidden="true" style={{color: '#2647E6'}}></i>
              </div>
            </button>
            <button
              type="button"
              className="d-flex flex-row justify-content-between bg-white my-3 p-2 border rounded-lg w-100"
              onClick={onAddKondisi}
            >
              <div className="d-flex flex-column text-left">
                <span className="font-weight-bold">Kondisi x Kondisi</span>
                <span style={{ color: "#687083" }}>
                  Menambahkan kondisi {"(filter and/or filter)"}
                </span>
              </div>
              <div className="my-auto pr-1">
                <i className="fa-arrow-right fa" aria-hidden="true" style={{color: '#2647E6'}}></i>
              </div>
            </button>
          </div>
        </div>
        {kondisi === "customer" && (
          <PopUpFilter
            isOpen={isFilter}
            onToggle={() => setFilter(!isFilter)}
            onAddFilter={handleAddFilter}
          />
        )}

        {kondisi === "product" && (
          <PopUpFilterProduct
            isOpen={isFilter}
            onToggle={() => setFilter(!isFilter)}
            onAddFilter={handleAddFilter}
            disableSKU={disableSKU}
          />
        )}
      </Modal>
    </Fragment>
  );
};

export default PopUpKondisi;
