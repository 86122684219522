import React, { Fragment, useState, useEffect } from "react";
import { Col, FormGroup, Label, Button } from "reactstrap";

const VideoUploader = ({ label, onFileSelect, selectedFile = null }) => {
  const [videoFile, setVideoFile] = useState(selectedFile);
  const [dragActive, setDragActive] = useState(false);

  useEffect(() => {
    setVideoFile(selectedFile);
  }, [selectedFile]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    processFile(file);
  };

  const processFile = (file) => {
    if (file) {
      const validExtensions = ["mp4", "mov"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!validExtensions.includes(fileExtension)) {
        alert("Hanya file dengan format MP4 dan MOV yang diperbolehkan.");
        return;
      }

      if (file.size > 10 * 1024 * 1024) {
        alert("Ukuran file tidak boleh lebih dari 10MB.");
        return;
      }

      setVideoFile(file);
      if (onFileSelect) onFileSelect(file);
    }
  };

  const handleRemoveFile = () => {
    setVideoFile(null);
    if (onFileSelect) onFileSelect(null);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragActive(false);

    const file = event.dataTransfer.files[0];
    processFile(file);
  };

  return (
    <Fragment>
      <FormGroup row>
        <Label className="font-weight-bold" for={`form${label}`} sm={2}>
          {label}
        </Label>
        <Col
          className="d-flex flex-column position-relative"
          id={`form${label}`}
          sm={10}
        >
          <div
            style={{
              border: dragActive ? "2px dashed #007bff" : "1px solid #ccc",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative",
              backgroundColor: dragActive ? "#f0f8ff" : "white",
            }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <input
              type="file"
              accept=".mp4, .mov"
              onChange={handleFileChange}
              style={{
                position: "absolute",
                opacity: 0,
                width: "80%",
                height: "100%",
                cursor: "pointer",
              }}
            />
            <div
              style={{
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                padding: "6px 12px",
                color: videoFile ? "#000" : "#687083",
                fontSize: 16,
              }}
            >
              {videoFile
                ? videoFile.name || videoFile
                : dragActive
                ? "Drop the file here"
                : "Select or drag and drop a video file here"}
            </div>
            {videoFile && (
              <Button
                size="sm"
                color="danger"
                style={{
                  padding: "3px 12px",
                  marginRight: 6,
                  width: "10%",
                }}
                onClick={handleRemoveFile}
              >
                Delete
              </Button>
            )}
          </div>
        </Col>
      </FormGroup>
    </Fragment>
  );
};

export default VideoUploader;
