import apiConfig from "../../api";
import {
  GET_LIST_PROMO,
  WATCH_RESPONSE_ALERT,
  GET_DETAIL_PROMO,
  GET_LIST_PROMO_BUNDLE,
  GET_DETAIL_BUNDLE,
  GET_LIST_PROMO_MASTER,
  GET_LIST_PROMO_PRODUCT,
  GET_LIST_APPROVAL,
  GET_LIST_PROMO_NEW,
  GET_LIST_PROMO_APPROVAL,
} from "../actionTypes";
import { toast } from "react-toastify";

export const getListPromo = (page, param, perPage) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/promos?page=${page}&search=${param ? param : ""}&perPage=${
          perPage ? perPage : 10
        }`
      );
      dispatch({
        type: GET_LIST_PROMO,
        payload: {
          loading: false,
          data: resp.data,
          err: false,
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const getListPromoNew = (query) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(`/api/admin/promo-master${query}`);
      dispatch({
        type: GET_LIST_PROMO_NEW,
        payload: {
          loading: false,
          data: resp.data,
          err: false,
        },
      });
      // return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const getListPromoApproval = (query) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(`/api/admin/master/list${query}`);
      dispatch({
        type: GET_LIST_PROMO_APPROVAL,
        payload: {
          loading: false,
          data: resp.data,
          err: false,
        },
      });
      // return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const getDetailPromoNew = (id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(`/api/admin/new-promo/${id}`);
      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const getListPromoTerkini = (page, param, perPage) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/promo-master?page=${page}&search=${
          param ? param : ""
        }&perPage=${perPage ? perPage : 10}`
      );
      dispatch({
        type: GET_LIST_PROMO_MASTER,
        payload: {
          loading: false,
          data: resp.data,
          err: false,
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const getListPromoBundle = (page, param, perPage) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/products/bundle?page=${page}&search=${
          param ? param : ""
        }&perPage=${perPage ? perPage : 10}`
      );

      dispatch({
        type: GET_LIST_PROMO_BUNDLE,
        payload: {
          loading: false,
          data: resp.data,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const updateStatusBundle = (id, status) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/products/bundle/${id}/${status}`
      );

      return resp;
    } catch (err) {
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const sendNotificationPromo = (promoId) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(
        "/api/admin/promos/notification",
        promoId
      );
      let dataResp = resp.data;
      let statusRemind = dataResp.data.status_remind;
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });

      if (statusRemind === "Remind Promo Not Set") {
        toast.error(`${dataResp.data.status_remind}`);
      }

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      return err;
    }
  };
};

export const deleteRowPromo = (promoId) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.delete(`/api/admin/promos/${promoId}`);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: false,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });

      return err;
    }
  };
};

export const deleteRowPromoNew = (promoId) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.delete(`/api/admin/promo-master/${promoId}`);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: false,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });

      return err;
    }
  };
};

export const createPromo = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(`/api/admin/promos`, payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: true,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });

      return err;
    }
  };
};

export const createNewPromo = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(`/api/admin/new-promo`, payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: true,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });

      return err;
    }
  };
};

export const updateNewPromo = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(`/api/admin/new-promo/update`, payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: true,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });

      return err;
    }
  };
};

export const createBundle = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(`/api/admin/products/bundle`, payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      return err;
    }
  };
};

export const updateBundle = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(
        `/api/admin/products/bundle/update`,
        payload
      );
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      return err;
    }
  };
};

export const deleteBundleProduct = (id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/products/bundle/deleted-item/${id}`
      );
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      return err;
    }
  };
};

export const deleteBundleImages = (id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.delete(
        `/api/admin/products/bundle/deleted-thumbnail/${id}`
      );
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      return err;
    }
  };
};

export const getDetailBundle = (id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(`/api/admin/products/bundle/${id}`);
      dispatch({
        type: GET_DETAIL_BUNDLE,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      return err;
    }
  };
};

export const setUpdatePromoReminder = (payload) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post("/api/admin/promos", payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });

        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const getDetailPromo = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(`/api/admin/promos/${payload}`);
      dispatch({
        type: GET_DETAIL_PROMO,
        payload: {
          loading: false,
          data: resp.data,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      return err;
    }
  };
};

export const updatePromo = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(`/api/admin/promos/update`, payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      return err;
    }
  };
};

export const updatePromoStatus = (id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(`/api/admin/promos/status/${id}`);

      return resp;
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      return err;
    }
  };
};

export const updatePromoStatusNew = (id, status) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(`/api/admin/promo-master/update-status/${id}/${status}`);

      return resp;
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      return err;
    }
  };
};

export const updatePromoImage = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(`/api/admin/promos/image`, payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: false,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });

      return err;
    }
  };
};

export const deletePromoImage = (idImage) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .delete(`/api/admin/promos/image/${idImage}`)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const getPromoProducts = (id, page, param, perPage) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/promos/product/${id}?page=${page}&search=${
          param ? param : ""
        }&perPage=${perPage ? perPage : 10}`
      );
      dispatch({
        type: GET_LIST_PROMO_PRODUCT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      return err;
    }
  };
};

export const delPromoProduct = (id) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .delete(`/api/admin/promos/product/${id}`)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            err: true,
          },
        });
      });
  };
};

export const createPromoProduct = (id, payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(
        `/api/admin/promos/product/${id}`,
        payload
      );
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: true,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });

      return err;
    }
  };
};

export const getListApproval = (page, param, perPage) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/master/list?page=${page}&search=${
          param ? param : ""
        }&perPage=${perPage ? perPage : 10}`
      );
      dispatch({
        type: GET_LIST_APPROVAL,
        payload: {
          loading: false,
          data: resp.data,
          err: false,
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const approveRejectRevisiPromoNew = (id, status) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/master/approval/${id}/${status}`
      );
      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};
