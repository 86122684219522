import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  getListTableFlashsale,
  deleteRowFlashsale,
  getCSVListFlashsale,
  updateFlashsale,
} from "@action/flashsaleAction";

import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumb from "@layout/breadcrumb";
import TableCSI from "@components/table";
import TableDropdown from "@components/customDropdown";
import CustomPagination from "@components/customPagination";
import BadgeStatus from "@components/badgeStatus";
import ButtonAction from "@components/styledComponents/btnTableAction";
import BtnDeactivate from "@components/styledComponents/btnDeactivate";
import BtnActivate from "@components/styledComponents/btnActivate";
import PaginationRow from "@components/paginationDataPerPage";
import DateRangePicker from "@components/dateRangePicker";

const FlashsaleLayout = () => {
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [mappedListData, setMappedListData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);

  const dispatch = useDispatch();
  const { listTableFlashsale } = useSelector((state) => state.FlashsaleReducer);

  useEffect(() => {
    fetchTable();
  }, []);

  useEffect(() => {
    if (listTableFlashsale.data && !listTableFlashsale.loading) {
      let respData = listTableFlashsale.data.data;
      if (respData === undefined || respData === null) return false;

      let mappedData = respData.data.map((item) => ({
        id: item.id,
        start_date: item.start_date,
        start_time: item.start_time,
        end_date: item.end_date,
        end_time: item.end_time,
        sesi: `${item.start_date} ${item.start_time} - ${item.end_date} ${item.end_time}`,
        jmlProduk: `${item.product} Produk` || "-",
        status: item.active || null,
      }));

      setMaxPageIndex(respData.last_page);
      setMappedListData(mappedData);
      setIsFetchedData(true);
    }
  }, [listTableFlashsale]);

  const fetchTable = () => {
    setTableColumn([
      {
        Header: () => null,
        id: "flashsaleColoumn",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            sortAble: true,
            width: 50,
          },
          {
            Header: "Sesi",
            accessor: "sesi",
            sortAble: true,
            width: 200,
          },
          {
            Header: "Jumlah Produk",
            accessor: "jmlProduk",
            sortAble: true,
            width: 100,
          },
          {
            Header: "Status",
            accessor: "status",
            sortAble: true,
            Cell: ({ row }) => <BadgeStatus status={row.original.status} />,
          },
          {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (
              <div className="d-flex">
                <ButtonAction className="my-auto mr-1">
                  <Link to={`/dashboard/flashsale/edit/${row.original.id}`}>
                    Edit
                  </Link>
                </ButtonAction>
                <div className="my-auto">
                  <TableDropdown
                    dropdownContent={[
                      {
                        label: "Detail",
                        type: "link",
                        link: `/dashboard/flashsale/detail/${row.original.id}`,
                      },
                      {
                        label: "Delete",
                        type: "delete",
                      },
                    ]}
                    row={row.original}
                    onDeleteAction={(id) => handleDeleteRow(id)}
                  />
                </div>
                <div>
                  {onCompareDate(
                    row.original.end_date,
                    row.original.end_time
                  ) ? (
                    row.original.status.toLowerCase() === "y" ||
                    row.original.status === 1 ? (
                      <BtnDeactivate
                        className="p-2 border rounded-lg"
                        onClick={() => onHandleDeactivate(row.original, "N")}
                      >
                        Deactivate
                      </BtnDeactivate>
                    ) : (
                      <BtnActivate
                        className="p-2 border rounded-lg"
                        onClick={() => onHandleDeactivate(row.original, "Y")}
                      >
                        Activate
                      </BtnActivate>
                    )
                  ) : null}
                </div>
              </div>
            ),
            disableSortBy: true,
            sortAble: false,
          },
        ],
      },
    ]);

    dispatch(getListTableFlashsale(1, ""));
  };

  function onCompareDate(date, endTime) {
    const apiDate = new Date(date);
    const [hours, minutes] = endTime.split(":").map(Number);
    const apiDateMilliseconds = apiDate.getTime();
    const apiTimeMilliseconds = (hours * 60 + minutes) * 60 * 1000;
    const currentDateMilliseconds = new Date().getTime();
    const combinedDateTimeMilliseconds =
      apiDateMilliseconds + apiTimeMilliseconds;
    return combinedDateTimeMilliseconds > currentDateMilliseconds;
  }

  const onHandleDeactivate = (data, param) => {
    const formData = new FormData();
    formData.append("active", param);
    dispatch(updateFlashsale(data.id, formData)).then((resp) => {
      if (resp.status !== 200) return;

      fetchTable();
    });
  };

  const handleSubmitSearch = (data) => {
    let strSearch = `start_date=${data.startDate}&end_date=${data.endDate}`;

    dispatch(getListTableFlashsale(1, strSearch));
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListTableFlashsale(page, ""));
  };

  const handleDeleteRow = (id) => {
    dispatch(deleteRowFlashsale(id)).then((resp) => {
      if (resp.status !== 200) return;

      fetchTable();
    });
  };

  const onChangePaginationRow = (perPage) => {
    dispatch(getListTableFlashsale(1, "", perPage));
  };

  return (
    <Fragment>
      {/* <Breadcrumb
        parent="Promotion"
        title="Flash Sale"
        urlParent={"/dashboard/promo"}
      /> */}
      <div className="d-flex flex-row justify-content-between">
        <h3>Flash Sale</h3>
        <Link to="/dashboard/promotion/add">
          <button className="px-3 py-2 rounded-lg btn-primary">
            New Promotion
          </button>
        </Link>
        {/* <Link to="/dashboard/flashsale/add">
          <button className="px-3 py-2 rounded-lg btn-primary">
            Add new Flash Sale
          </button>
        </Link> */}
      </div>
      {/* <Container fluid={true}> */}
      <Row>
        <Col>
          {/* <Card className="p-0"> */}
          <CardBody className="p-0">
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex">
                <DateRangePicker sendDateRange={handleSubmitSearch} />
              </div>
              <div className="d-flex">
                <Button
                  outline
                  color="primary"
                  className="mx-2 py-1"
                  onClick={() => dispatch(getCSVListFlashsale())}
                >
                  Export as CSV
                </Button>
                {/* <Link to="/dashboard/flashsale/add">
                  <Button className="py-2" color="primary" outline>
                    Add new Flash Sale
                  </Button>
                </Link> */}
              </div>
            </div>

            {isFetchedData ? (
              <TableCSI
                data={mappedListData}
                columns={tableColumn}
                initialSortBy={initialSortBy}
              />
            ) : null}

            <div className="d-flex justify-content-between">
              <PaginationRow onChangePaginationRow={onChangePaginationRow} />
              <CustomPagination
                handlePageClick={handlePageClick}
                pageCount={maxPageIndex}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
              />
            </div>
          </CardBody>
          {/* </Card> */}
        </Col>
      </Row>
      {/* </Container> */}
    </Fragment>
  );
};

export default FlashsaleLayout;
