import React, { useState } from "react";
import Breadcrumbs from "@layout/breadcrumb";
import FlashSalePromo from "../flashsale/add/index";
import NewPromotion from "./add/index";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

const SelectPromo = () => {
  const [selectedType, setSelectedType] = useState("1");

  return (
    <div>
      <Breadcrumbs
        parent="Promotion"
        title={
          selectedType === "2" ? "Add New Flash Sale" : "Add New Promotion"
        }
        urlParent={"/dashboard/promotion"}
      />
      <Nav tabs>
        <NavItem>
          <NavLink
            className={selectedType === "1" ? "active" : ""}
            onClick={() => setSelectedType("1")}
          >
            Other
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={selectedType === "2" ? "active" : ""}
            onClick={() => setSelectedType("2")}
          >
            Flash Sale
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={selectedType}>
        <TabPane tabId="1">
          <NewPromotion />
        </TabPane>
        <TabPane tabId="2">
          <FlashSalePromo />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default SelectPromo;
