import { Fragment, useEffect, useState } from "react";
import SwitchAndOr from "./SwitchAndOr";
import PopUpKondisi from "./PopUpKondisi";
import FilterByManufacture from "./filterByManufacture";
import FilterByKategori from "./filterByKategori";
import FilterBySKU from "./filterBySKU";

const FilterByKondisiProduct = ({
  onDeleteFilter,
  item,
  productCondition,
  setProductCondition,
  isAddKondisi,
  setAddKondisi,
}) => {
  const [openModal, setModal] = useState(false);

  //manufactur
  const [isManufaktur, setManufaktur] = useState(false);
  const [manufakturData, setManufakturData] = useState({
    value: "",
    type: "manufactur",
  });
  const [selectedManufactur, setSelectedManufactur] = useState([]);

  // Kategori
  const [isKategori, setKategori] = useState(false);
  const [kategoriData, setKategoriData] = useState({
    value: "",
    type: "kategori",
  });
  const [selectedKategori, setSelectedKategori] = useState([]);

  //SKU
  const [isSKU, setSKU] = useState(false);
  const [skuData, setSkuData] = useState({
    value: "",
    type: "sku",
    group: "kelipatan",
  });
  const [selectedSKU, setSelectedSKU] = useState([]);

  function handleAddFilter(val) {
    switch (val) {
      case "manufactur":
        setManufaktur(true);
        break;
      case "kategori":
        setKategori(true);
        break;
      case "sku":
        setSKU(true);
        break;
      default:
        break;
    }
  }

  const handleChangeItems = (val, data) => {
    setAddKondisi((prevData) =>
      prevData.map((kondisi) => {
        const newData = {
          value: data.value,
          type: data.type,
          items: val.map((item) => item),
        };

        if (kondisi.name === item.name) {
          if (!kondisi?.data || kondisi.data.length === 0) {
            return {
              ...kondisi,
              data: [newData],
            };
          }

          const newConditions = kondisi.data.some(
            (itm) => itm.type === data.type
          )
            ? kondisi.data.map((itm) =>
                itm.type === data.type ? newData : itm
              )
            : [...kondisi.data, newData];

          return {
            ...kondisi,
            data: newConditions,
          };
        }

        return kondisi;
      })
    );
  };

  const filterDataSku = (dataArray) => {
    return dataArray.map((obj) => ({
      relation_id: obj.id || "",
      data: obj?.data?.length ? obj.data : [],
    }));
  };
  const transformSelectedDataSKU = (data) => {
    if (!data?.length) return [];
    return data.map((item) => {
      if (item?.detail) {
        return {
          ...item.detail,
          name: item?.detail?.name || "-",
          id: item?.detail?.id || 0,
          data: item?.promo_tier?.length
            ? item.promo_tier.map((tier) => {
                return {
                  // is_active: tier.is_active,
                  type: tier.type,
                  // type_condition: tier.type_condition,
                  group: tier.type_condition,
                  value: tier.value,
                };
              })
            : [],
        };
      }
      return { name: "-", id: 0 };
    });
  };
  const handleChangeSKUItems = () => {
    setAddKondisi((prevData) =>
      prevData.map((kondisi) => {
        const skuId = kondisi?.data?.length && kondisi.data.find((x)=> x.type === 'sku')
        const newData = {
          value: skuData.value,
          type: skuData.type,
          items: filterDataSku(selectedSKU),
          id: skuId?.id || '',
          promo_condition_id: skuId?.promo_condition_id || '',
          // items: selectedSKU.map((item) => item.id.toString()),
        };

        if (kondisi.name === item.name) {
          if (!kondisi?.data || kondisi.data.length === 0) {
            return {
              ...kondisi,
              data: [newData],
            };
          }

          const newConditions = kondisi.data.some(
            (itm) => itm.type === skuData.type
          )
            ? kondisi.data.map((itm) =>
                itm.type === skuData.type ? newData : itm
              )
            : [...kondisi.data, newData];

          return {
            ...kondisi,
            data: newConditions,
          };
        }

        return kondisi;
      })
    );
  };

  const handleChangeValue = (newValue, dataType) => {
    setAddKondisi((prevData) =>
      prevData.map((kondisi) => {
        const newConditions =
          kondisi.data && kondisi.data.length > 0
            ? kondisi.data.map((itm) => {
                if (itm.type === dataType) {
                  return {
                    ...itm,
                    value: newValue,
                  };
                }
                return itm;
              })
            : [];

        return {
          ...kondisi,
          data: newConditions,
        };
      })
    );
  };

  const handleChangeAndOr = (value, name) => {
    // setCustomerCondition(value);
    setAddKondisi((prevData) =>
      prevData.map((kondisi) => {
        if (kondisi.name === name) {
          return { ...kondisi, condition: value };
        }
        return kondisi;
      })
    );
  };

  const removeItems = (type) => {
    setAddKondisi((prevData) =>
      prevData.map((kondisi) => {
        const newItems =
          kondisi.data && kondisi.data.length > 0
            ? kondisi.data.filter((itm) => itm.type !== type)
            : [];

        return {
          ...kondisi,
          data: newItems,
        };
      })
    );
  };

  useEffect(() => {
    const manufactur = item?.data?.length
      ? item.data.find((x) => x.type === "manufactur")
      : null;
    if (manufactur) {
      setManufaktur(true);
      setManufakturData({ value: manufactur.value, type: "manufactur" });
      setSelectedManufactur(manufactur?.items?.length ? manufactur.items : []);
    }
    const kategori = item?.data?.length
      ? item.data.find((x) => x.type === "kategori")
      : null;
    if (kategori) {
      setKategori(true);
      setKategoriData({ value: kategori.value, type: "kategori" });
      setSelectedKategori(kategori?.items?.length ? kategori.items : []);
    }
    const sku = item?.data?.length
      ? item.data.find((x) => x.type === "sku")
      : null;
    if (sku) {
      const group = sku?.promo_value_detail?.length ? sku?.promo_value_detail[0]?.promo_tier[0]?.type_condition : "";
      setSKU(true);
      setSkuData({ value: sku.value, type: "sku", group });
      setSelectedSKU(transformSelectedDataSKU(sku?.promo_value_detail));
    }
  }, []);

  useEffect(() => {
    if (selectedSKU?.length) {
      handleChangeSKUItems();
    }
  }, [selectedSKU]);

  return (
    <Fragment>
      <div className="bg-light p-3 border rounded-lg text-center text-dark mb-2">
        <div className="d-flex flex-row justify-content-between mb-2">
          <h5>Kondisi {item.name}</h5>
          <div className="d-flex">
            <SwitchAndOr
							key={'filterByKondisiProduct'+item.name}
              // value={productCondition}
              // onChange={(val) => setProductCondition(val)}
              value={item.condition}
              onChange={(val) => handleChangeAndOr(val, item.name)}
            />
            <button
              type="button"
              className="ml-2 btn btn-primary"
              onClick={() => setModal(!openModal)}
            >
              {"+"} Tambah Kondisi
            </button>
            <button className="border-0 bg-none" onClick={onDeleteFilter}>
              <i
                className="fa fa-trash"
                aria-hidden="true"
                style={{ color: "red" }}
              ></i>
            </button>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center my-2 p-3">
          {isManufaktur && (
            <FilterByManufacture
              onToggle={() => {
                setManufakturData({ value: "", type: "manufactur" });
                setSelectedManufactur([]);
                setManufaktur(!isManufaktur);
                removeItems("manufactur");
              }}
              selectedData={selectedManufactur}
              setSelectedData={(val) => {
                setSelectedManufactur(val);
                handleChangeItems(val, manufakturData);
              }}
              filterData={manufakturData}
              setFilterData={(e) => {
                setManufakturData(e);
                handleChangeValue(e.value, manufakturData.type);
              }}
            />
          )}
          {isKategori && (
            <FilterByKategori
              onToggle={() => {
                setKategoriData({ value: "", type: "kategori" });
                setSelectedKategori([]);
                setKategori(!isKategori);
                removeItems("kategori");
              }}
              selectedData={selectedKategori}
              setSelectedData={(val) => {
                setSelectedKategori(val);
                handleChangeItems(val, kategoriData);
              }}
              filterData={kategoriData}
              setFilterData={(e) => {
                setKategoriData(e);
                handleChangeValue(e.value, kategoriData.type);
              }}
            />
          )}
          {isSKU && (
            <FilterBySKU
              idKP={item.name}
              typeRadio="AddKondisi"
              typeFilter="kondisi"
              onToggle={() => {
                setSkuData({ value: "", type: "sku", group: "kelipatan" });
                setSelectedSKU([]);
                setSKU(!isSKU);
                removeItems("sku");
              }}
              selectedData={selectedSKU}
              setSelectedData={setSelectedSKU}
              // setSelectedData={(val) => {
              //   setSelectedSKU(val);
              //   handleChangeItems(val, skuData);
              // }}
              filterData={skuData}
              setFilterData={(e) => {
                setSkuData(e);
                handleChangeValue(e.value, skuData.type);
              }}
            />
          )}

          {!isManufaktur && !isKategori && !isSKU ? (
            <div className="bg-light p-3 rounded-lg text-center text-dark">
              <span>Silahkan untuk menambahkan filter</span>
            </div>
          ) : (
            ""
          )}
        </div>

        <PopUpKondisi
          kondisi="product"
          isOpen={openModal}
          onToggle={() => setModal(!openModal)}
          onAddFilter={handleAddFilter}
          disableSKU={true}
        />
      </div>
    </Fragment>
  );
};

export default FilterByKondisiProduct;
