import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

//component
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CustomInput,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Breadcrumb from "@layout/breadcrumb";
import TableCSI from "@components/table";
import BtnWhite from "@components/styledComponents/btnWhite";
import iconClose from "@assets/images/Close.svg";
import { ModalDialog } from "@components/modal/ModalDialog";
import ModalContent from "@components/modal/ModalContent";
import AddProdukFlashsale from "../customComponent/addProduk";
import TimeInput from "@components/timePicker";
import SpinnerLoading from "@components/spinnerLoading";

//redux action
import { createFlashsale } from "@action/flashsaleAction";

//css styles
import {
  wrapperInputThumbnail,
  styleInputThumbnail,
  styleInputbanner,
  btnUploadBanner,
  containerMutipleBanner,
  btnDeleteBanner,
  ImgButton,
} from "../style/cssStyle";

const FlashsaleCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const locationKey = location.key;
  const { alertResponse } = useSelector((state) => state.AlertReducer);
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [imgThumbnailSrc, setImgThumbnailSrc] = useState("");
  const [multipleImageSrc, setMultipleImageSrc] = useState([]);
  const imageBannerID = useRef(0);
  const [modalState, setModalState] = useState(null);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [startTimeHour, setStartTimeHour] = useState("");
  const [startTimeMinute, setStartTimeMinute] = useState("");
  const [endTimeHour, setEndTimeHour] = useState("");
  const [endTimeMinute, setEndTimeMinute] = useState("");
  const [formBody, setFormBody] = useState({
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    thumbnail: null,
    banner: [],
    produk: [],
  });
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    if (alertResponse.status === 200 && alertResponse.to === location.key) {
      history.push("/dashboard/flashsale");
    }
  }, [alertResponse]);

  useEffect(() => {
    setIsFetchedData(true);
    setTableColumn([
      {
        Header: () => null,
        id: "flashsaleProduk",
        columns: [
          {
            Header: "Name",
            accessor: "name",
            sortAble: true,
          },
          {
            Header: "Harga",
            accessor: "harga",
            sortAble: true,
          },
          {
            Header: "Diskon",
            accessor: "diskon",
            width: 200,
          },
          {
            Header: "Stock",
            accessor: "stock",
          },
          {
            Header: "Batas Pembelian",
            accessor: "min_buy",
          },
          {
            Header: "Status",
            accessor: "status",
          },
          {
            Header: "Action",
            accessor: "action",
            sortAble: false,
          },
        ],
      },
    ]);
  }, []);

  useEffect(() => {
    if (formBody.produk.length > 0) {
      const onDeleteRowProduk = (data) => {
        const newArr = formBody.produk.filter((item) => item.id !== data.id);
        setFormBody({ ...formBody, produk: newArr });
      };

      const onChangeHargaDiskon = (e, param) => {
        const newArr = formBody.produk.map((obj) =>
          obj.id === param.id ? { ...obj, harga_diskon: e.target.value } : obj
        );
        setFormBody({ ...formBody, produk: newArr });
        toast.success(`Edit saved.`, { position: "bottom-right" });
      };

      const onChangeDiskon = (e, param) => {
        const newArr = formBody.produk.map((obj) =>
          obj.id === param.id ? { ...obj, diskon: e.target.value } : obj
        );
        setFormBody({ ...formBody, produk: newArr });
        toast.success(`Edit saved.`, { position: "bottom-right" });
      };

      const onChangeStock = (e, param) => {
        const newArr = formBody.produk.map((obj) =>
          obj.id === param.id ? { ...obj, stock: e.target.value } : obj
        );
        setFormBody({ ...formBody, produk: newArr });
        toast.success(`Edit saved.`, { position: "bottom-right" });
      };

      const onChangeMinBuy = (e, param) => {
        const newArr = formBody.produk.map((obj) =>
          obj.id === param.id ? { ...obj, min_buy: e.target.value } : obj
        );
        setFormBody({ ...formBody, produk: newArr });
        toast.success(`Edit saved.`, { position: "bottom-right" });
      };

      const onChangeStatus = () => {};

      const handleChangeDiskonType = (e, data) => {
        const newArr = formBody.produk.map((obj) =>
          obj.id === data.id ? { ...obj, discount_type: e.target.value } : obj
        );
        setFormBody({ ...formBody, produk: newArr });
        toast.success(`Edit saved.`, { position: "bottom-right" });
      };

      function validateNumberInput(input) {
        // Remove non-numeric characters except leading zeros
        let sanitizedValue = input.target.value.replace(/[^\d]+/g, "");
        // Remove leading zeros, except if it's the only character
        sanitizedValue = sanitizedValue.replace(/^0+(.)/, "$1");
        // Add comma as a thousands separator
        sanitizedValue = sanitizedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        input.target.value = sanitizedValue;
      }

      setTableColumn([
        {
          Header: () => null,
          id: "flashsaleProduk",
          columns: [
            {
              Header: "Name",
              accessor: "name",
              sortAble: true,
              width: 200,
            },
            {
              Header: "Harga",
              accessor: "harga",
              sortAble: true,
            },
            {
              Header: "Diskon",
              accessor: "diskon",
              Cell: ({ row }) => (
                <div>
                  <div className="d-flex flex-row">
                    <Input
                      type="select"
                      name="selectMulti"
                      onChange={(e) => handleChangeDiskonType(e, row.original)}
                      value={row.original.discount_type}
                      style={{ maxWidth: "65px" }}
                    >
                      <option value="amount">Rp.</option>
                      <option value="persentage">%</option>
                    </Input>
                    <Input
                      className="mx-1"
                      type="text"
                      defaultValue={row.original.diskon}
                      onInput={validateNumberInput}
                      onBlur={(e) => onChangeDiskon(e, row.original)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter")
                          onChangeHargaDiskon(e, row.original);
                      }}
                      style={{ maxWidth: "200px" }}
                    />
                  </div>
                </div>
              ),
              width: 250,
            },
            {
              Header: "Stock",
              accessor: "stock",
              Cell: ({ row }) => (
                <Input
                  type="number"
                  min={0}
                  defaultValue={row.original.stock}
                  onBlur={(e) => onChangeStock(e, row.original)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") onChangeStock(e, row.original);
                  }}
                />
              ),
            },
            {
              Header: "Batas Pembelian",
              accessor: "min_buy",
              Cell: ({ row }) => (
                <Input
                  type="number"
                  min={0}
                  defaultValue={row.original.min_buy}
                  onBlur={(e) => onChangeMinBuy(e, row.original)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") onChangeMinBuy(e, row.original);
                  }}
                />
              ),
            },
            {
              Header: "Status",
              accessor: "status",
              Cell: ({ row }) => (
                <CustomInput
                  id={`idStatusProduk${row.original.name}`}
                  name={`idStatusProduk${row.original.name}`}
                  type="switch"
                  className="m-2"
                  defaultChecked
                />
              ),
              width: 80,
            },
            {
              Header: "Action",
              accessor: "action",
              sortAble: false,
              Cell: ({ row }) => (
                <BtnWhite
                  onClick={() => onDeleteRowProduk(row.original)}
                  style={{ width: "80px", padding: "8px 10px" }}
                >
                  Hapus
                </BtnWhite>
              ),
              width: 80,
            },
          ],
        },
      ]);
    }
  }, [formBody.produk]);

  const isImage = (imgType) => {
    switch (imgType) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return true;
    }
    return false;
  };

  const handleCheckForm = () => {
    if (
      formBody.start_date === "" ||
      formBody.end_date === "" ||
      startTimeHour === "" ||
      endTimeHour === "" ||
      formBody.thumbnail === null ||
      formBody.banner.length < 1 ||
      formBody.produk.length < 1
    ) {
      return false;
    }
    return true;
  };

  const handleOnChangeInputThumbnail = (e) => {
    if (e.target.files.length > 0) {
      const filesImg = e.target.files[0];
      if (filesImg.size > 2000000) {
        toast.error(`Max file size is 2MB`);
        document.getElementById("selectThumbnail").value = "";
      } else {
        if (!isImage(filesImg.type)) {
          toast.error(`Select appropiate image file.`);
          document.getElementById("selectThumbnail").value = "";
        } else {
          const imgSrc = URL.createObjectURL(filesImg);
          setImgThumbnailSrc(imgSrc);
          setFormBody({ ...formBody, thumbnail: filesImg });
        }
      }
    }
  };

  const handleUploadMultipleImg = (e) => {
    if (e.target.files.length > 0) {
      const filesImg = e.target.files[0];
      if (filesImg.size > 2000000) {
        toast.error(`Max file size is 2MB`);
        document.getElementById("selectBanner").value = "";
      } else {
        if (!isImage(filesImg.type)) {
          toast.error(`Select appropiate image file.`);
          document.getElementById("selectBanner").value = "";
        } else {
          const imgSrc = URL.createObjectURL(filesImg);
          const objImage = {
            id: imageBannerID.current,
            src: imgSrc,
            fileBlob: filesImg,
          };
          setMultipleImageSrc((multipleImageSrc) => [
            ...multipleImageSrc,
            objImage,
          ]);
          imageBannerID.current = imageBannerID.current + 1;

          const newArrImage = [...formBody.banner, objImage.fileBlob];
          setFormBody({ ...formBody, banner: newArrImage });
        }
      }
    }
  };

  const showModal = (row) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: "remind",
      modalTitle: "Delete Banner",
      modalData: row,
      closeIcon: true,
    }));
  };

  const handleRemoveBanner = (item) => {
    setMultipleImageSrc(multipleImageSrc.filter((arr) => arr.id !== item.id));
    setModalState(null);
  };

  const handleSubmitProduct = (data) => {
    let mappedData = data.map((item) => ({
      id: item.id,
      name: item.name,
      harga: item.harga || "-",
      harga_diskon: item.harga_diskon || 0,
      diskon: item.diskon || 0,
      discount_type: item.discount_type || "amount",
      stock: item.stock || 0,
      min_buy: item.min_buy || 0,
      status: item.status || false,
    }));

    const objectExists = (targetArray, propertyValue) =>
      targetArray.some((item) => item.id === propertyValue);
    const filteredArray = mappedData.filter(
      (item) => !objectExists(formBody.produk, item.id)
    );
    const mergedArray = formBody.produk.concat(filteredArray);

    setFormBody({ ...formBody, produk: mergedArray });
  };

  const toggleAddProduct = () => {
    setShowAddProduct(!showAddProduct);
  };

  const handleConvertTimeInput = () => {
    if (startTimeHour && endTimeHour === "") return;

    setFormBody({
      ...formBody,
      start_time: `${
        startTimeHour.length == 1 ? "0" + startTimeHour : startTimeHour
      }:${startTimeMinute || "00"}`,
      end_time: `${endTimeHour.length == 1 ? "0" + endTimeHour : endTimeHour}:${
        endTimeMinute || "00"
      }`,
    });
  };

  const validateDate = () => {
    if (formBody.start_date !== "" && formBody.end_date) {
      const today = new Date();
      const todayDate = moment.utc(today).format("YYYY-MM-DD");
      if (formBody.start_date > formBody.end_date) return 1;
      if (todayDate > formBody.end_date) return 1;
      if (formBody.start_date < formBody.end_date) return -1;
    }
    return 0;
  };

  const handleSubmitFlashsale = () => {
    if (!handleCheckForm())
      return toast.error(`Please fill all field and upload necessary files.`);
    else if (validateDate() === 1)
      return toast.error(
        "End Date cannot be earlier than Start Date or Today Date"
      );

    setDisableSubmit(true);
    handleConvertTimeInput();

    let dataForm = new FormData();
    dataForm.append("start_date", formBody.start_date);
    dataForm.append("end_date", formBody.end_date);
    dataForm.append(
      "start_time",
      `${startTimeHour.length == 1 ? "0" + startTimeHour : startTimeHour}:${
        startTimeMinute.length == 1 ? "0" + startTimeMinute : startTimeMinute || "00"
      }`
    );
    dataForm.append(
      "end_time",
      `${endTimeHour.length == 1 ? "0" + endTimeHour : endTimeHour}:${
        endTimeMinute.length == 1 ? "0" + endTimeMinute : endTimeMinute || "00"
      }`
    );
    dataForm.append("thumbnail", formBody.thumbnail);

    for (let x of formBody.banner) {
      dataForm.append("banner[]", x);
    }
    for (let i of formBody.produk) {
      dataForm.append("product_id[]", i.id);
    }
    for (let i of formBody.produk) {
      dataForm.append("discount[]", i.diskon);
    }
    for (let i of formBody.produk) {
      dataForm.append("product_stock[]", i.stock);
    }
    for (let i of formBody.produk) {
      dataForm.append("product_limit_stock[]", i.min_buy);
    }
    for (let i of formBody.produk) {
      dataForm.append("discount_type[]", i.discount_type);
    }

    dispatch(createFlashsale(dataForm, locationKey));

    setTimeout(() => {
      setDisableSubmit(false);
    }, 1000);
  };

  return (
    <Fragment>
      {/* <Breadcrumb
        parent="Flash Sale"
        title="Add New Flash Sale"
        // urlParent="/dashboard/flashsale"
        urlParent={"/dashboard/promotion"}
      /> */}
      {/* <Container fluid> */}
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGroup className="d-flex">
                    <Label for="switchEventActive">
                      <b>Event Active</b>
                    </Label>
                    <CustomInput
                      type="switch"
                      id="switchEventActive"
                      name="switchEventActive"
                      className="mx-2"
                      defaultChecked
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <b>
                        Waktu Event <span style={{ color: "red" }}>*</span>
                      </b>
                    </Label>
                    <div className="d-xl-flex">
                      <div className="d-flex">
                        <Input
                          className="mr-1"
                          type="date"
                          name="date"
                          id="selectStartDate"
                          max="9999-12-31"
                          min="1700-12-31"
                          value={formBody.start_date}
                          onChange={(e) =>
                            setFormBody({
                              ...formBody,
                              start_date: e.target.value,
                            })
                          }
                          style={{ minWidth: "350px" }}
                        />
                        <TimeInput
                          hour={startTimeHour}
                          setHour={setStartTimeHour}
                          minute={startTimeMinute}
                          setMinute={setStartTimeMinute}
                        />
                      </div>
                      <span className="mx-3 my-2">sampai</span>
                      <div className="d-flex">
                        <Input
                          className="mr-1"
                          type="date"
                          name="date"
                          id="selectEndDate"
                          max="9999-12-31"
                          min="1700-12-31"
                          value={formBody.end_date}
                          onChange={(e) =>
                            setFormBody({
                              ...formBody,
                              end_date: e.target.value,
                            })
                          }
                          style={{ minWidth: "350px" }}
                        />
                        <TimeInput
                          hour={endTimeHour}
                          setHour={setEndTimeHour}
                          minute={endTimeMinute}
                          setMinute={setEndTimeMinute}
                        />
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="selectThumbnail" sm={12}>
                      <b>Foto Thumbnail</b>
                    </Label>
                    <Col style={wrapperInputThumbnail} sm={12}>
                      <button className="p-0" style={btnUploadBanner}>
                        <ImgButton
                          className="mx-auto"
                          src={imgThumbnailSrc}
                          width={240}
                          height={180}
                        />
                      </button>

                      <Input
                        type="file"
                        name="selectThumbnail"
                        id="selectThumbnail"
                        accept="image/*"
                        onChange={handleOnChangeInputThumbnail}
                        style={styleInputThumbnail}
                      />
                      <p className="my-1" style={{ fontSize: "12px" }}>
                        Format file jpeg, jpg, png
                      </p>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="selectBanner" sm={12}>
                      <b>Foto Banner</b>
                    </Label>
                    <Col sm={12}>
                      <Row>
                        <Col sm={6} xl={3} style={wrapperInputThumbnail}>
                          <button style={btnUploadBanner}>Tambah Gambar</button>
                          <Input
                            type="file"
                            name="selectBanner"
                            id="selectBanner"
                            accept="image/*"
                            onChange={handleUploadMultipleImg}
                            style={styleInputbanner}
                          />
                        </Col>
                        <Col
                          className="py-2"
                          sm={6}
                          xl={9}
                          style={containerMutipleBanner}
                        >
                          {multipleImageSrc.length > 0
                            ? multipleImageSrc.map((data, i) => (
                                <div
                                  key={i}
                                  className="mx-2"
                                  style={{
                                    position: "relative",
                                    maxWidth: "240px",
                                    maxHeight: "180px",
                                    display: "inline-block",
                                  }}
                                >
                                  <img
                                    src={iconClose}
                                    onClick={() => showModal(data)}
                                    style={btnDeleteBanner}
                                  />
                                  <img
                                    src={data.src}
                                    style={{ height: "100%", width: "100%" }}
                                  ></img>
                                </div>
                              ))
                            : ""}
                        </Col>
                      </Row>
                      <span className="my-1" style={{ fontSize: "12px" }}>
                        Format file jpeg, jpg, png
                      </span>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <h4>Produk Flash Sale</h4>
                  <Button
                    onClick={() => setShowAddProduct(true)}
                    color="primary"
                    outline
                  >
                    Add Produk
                  </Button>
                </div>
                <div className="my-2">
                  {isFetchedData ? (
                    <TableCSI
                      data={formBody.produk}
                      columns={tableColumn}
                      key={formBody.produk}
                      initialSortBy={initialSortBy}
                    />
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="d-flex justify-content-end my-2" sm={12}>
            <BtnWhite
              onClick={() => history.push("/dashboard/flashsale")}
              style={{ width: "150px" }}
            >
              Cancel
            </BtnWhite>

            <Button
              className="mx-2 px-auto"
              color="primary"
              onClick={() => handleSubmitFlashsale()}
              disabled={disableSubmit}
              style={{ width: "150px" }}
            >
              {disableSubmit ? <SpinnerLoading /> : "Submit"}
            </Button>
          </Col>
        </Row>

        <Fragment>
          <ModalDialog
            open={modalState?.openModal ?? false}
            type={modalState?.modalType ?? ""}
            footer={false}
            title={modalState?.modalTitle ?? ""}
            titleFontSize={18}
            backdropClose={true}
            onClose={() => {
              setModalState(null);
            }}
            maxWidth={384}
          >
            <ModalContent
              onClose={() => {
                setModalState(null);
              }}
              onSubmit={() => {
                handleRemoveBanner(modalState?.modalData);
                // handleDelete(modalState?.modalData);
              }}
              data={modalState?.modalData ?? null}
              description={`Are you sure you want to delete this banner?`}
              descClose={"Cancel"}
              descSubmit={"Delete"}
            />
          </ModalDialog>
        </Fragment>
        <Fragment>
          <AddProdukFlashsale
            showAddProduct={showAddProduct}
            toggleAddProduct={toggleAddProduct}
            handleSubmitProduct={handleSubmitProduct}
          />
        </Fragment>
      {/* </Container> */}
    </Fragment>
  );
};

export default FlashsaleCreate;
