import { Fragment, useEffect, useState } from "react";

import Breadcrumb from "@layout/breadcrumb";
import TablePromo from "./tablePromo";
import TableApproval from "./tableApproval";
import FlashSale from "../flashsale/index";
import { useParams, useLocation } from "react-router-dom";

const NewPromotion = () => {
  const test = useParams();
  const location = useLocation();
  const hashFragment = location.hash;

  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    if (hashFragment === "#tab=1") {
      setActiveTab(1);
    }
  }, [hashFragment]);

  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title="Promotion"
        urlParent={"/dashboard/analytics"}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="bg-white shadow-sm p-3 rounded-lg col">
            <nav>
              <div
                className="border-bottom nav nav-pills"
                id="nav-tab"
                role="tablist"
              >
                <button
                  className={`nav-link bg-white rounded-0 ${
                    activeTab === 0
                      ? "active text-primary border-bottom border-primary"
                      : "text-dark"
                  }`}
                  id="nav-promo-tab"
                  type="button"
                  aria-controls="nav-promo"
                  aria-selected="true"
                  onClick={() => setActiveTab(0)}
                >
                  Buat Promo
                </button>
                <button
                  className={`nav-link bg-white rounded-0 ${
                    activeTab === 1
                      ? "active text-primary border-bottom border-primary"
                      : "text-dark"
                  }`}
                  id="nav-approval-tab"
                  type="button"
                  aria-controls="nav-approval"
                  aria-selected="false"
                  onClick={() => setActiveTab(1)}
                >
                  Approval
                </button>
                <button
                  className={`nav-link bg-white rounded-0 ${
                    activeTab === 2
                      ? "active text-primary border-bottom border-primary"
                      : "text-dark"
                  }`}
                  id="nav-approval-tab"
                  type="button"
                  aria-controls="nav-approval"
                  aria-selected="false"
                  onClick={() => setActiveTab(2)}
                >
                  Flash Sale
                </button>
              </div>
            </nav>
            <div className="my-2 py-2 tab-content" id="nav-tabContent">
              {activeTab === 0 && (
                <div aria-labelledby="nav-promo-tab">
                  <TablePromo />
                </div>
              )}
              {activeTab === 1 && (
                <div aria-labelledby="nav-approval-tab">
                  <TableApproval />
                </div>
              )}
              {activeTab === 2 && (
                <div aria-labelledby="nav-approval-tab">
                  <FlashSale />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewPromotion;
