import { InputRadio, InputText } from '@components/formComponent';
import SelectList from '@components/selectList';
import React, { useEffect, useState } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import SearchableDropdown from '@components/SearchableDropdown';
import apiConfig from '../../../../api';

// Komponen Utama
const BenefitPromo = ({
	benefitData,
	setBenefitData,
	benefitSuperTier,
	setBenefitSuperTier,
	benefitKelipatan,
	setBenefitKelipatan,
	supertier
}) => {
	const [dataProduct, setDataProduct] = useState([]);
	const [searchProduct, setSearchProduct] = useState('');

	const getData = async () => {
		const res = await apiConfig.get(
			`/api/admin/products?search=${searchProduct}&perPage=100`
		);
		const data = res?.data?.data?.length ? res?.data?.data : [];
		setDataProduct(transformData(data));
	};

	const transformData = (data) => {
		return data.map((item) => ({
			id: item.id,
			name: item.name,
			image: item?.images?.length ? item.images[0].image_url : null,
			...item,
		}));
	};

	useEffect(() => {
		getData();
	}, [searchProduct]);

	// Handler untuk mengubah type_benefit
	const handleTypeBenefitChange = (index, newTypeBenefit) => {
		const type =
			newTypeBenefit === 'potongan-harga' ? 'percentage' : 'custom';
		const updatedBenefitData = benefitData.map((benefit, idx) =>
			idx === index
				? { benefit, type_benefit: newTypeBenefit, type }
				: benefit
		);
		setBenefitData(updatedBenefitData);
	};

	// Handler untuk mengubah type
	const handleTypeChange = (index, newType) => {
		const updatedBenefitData = benefitData.map((benefit, idx) =>
			idx === index ? { ...benefit, type: newType, value: '' } : benefit
		);
		setBenefitData(updatedBenefitData);
	};

	// Handler untuk mengubah value
	const handleValueChange = (index, newValue) => {
		const updatedBenefitData = benefitData.map((benefit, idx) =>
			idx === index ? { ...benefit, value: newValue } : benefit
		);
		setBenefitData(updatedBenefitData);
	};

	// Handler untuk mengubah maks_benefit
	const handleMaksBenefitChange = (index, newMaksBenefit) => {
		const updatedBenefitData = benefitData.map((benefit, idx) =>
			idx === index ? { ...benefit, maks_benefit: newMaksBenefit } : benefit
		);
		setBenefitData(updatedBenefitData);
	};

	// Handler untuk mengubah qty_benefit on attributes benefit
	const handleQtyBenefitChange = (index, newMaksBenefit) => {
		const updatedBenefitData = benefitData.map((benefit, idx) =>
			idx === index ? { ...benefit, benefit: newMaksBenefit } : benefit
		);
		setBenefitData(updatedBenefitData);
	};

	// Handler untuk mengubah data product
	const handleSelectProduct = (index, val) => {
		const updatedBenefitData = benefitData.map((benefit, idx) =>
			idx === index
				? { ...benefit, value: arrayToString(val), selectedProduct: val }
				: benefit
		);
		setBenefitData(updatedBenefitData);
	};
	const arrayToString = (data) => {
		return data.map((item) => item.id).join(',');
	};
	const stringToArray = (str) => {
		if (!str || str.trim() === '') {
			return [];
		}
		return str.split(',').map((id) => {
			const foundItem = dataProduct.find(
				(item) => item.id === parseInt(id, 10)
			);
			return {
				id: parseInt(id, 10),
				name: foundItem?.name || '',
				image: foundItem?.image || '',
			};
		});
	};

	const findBenefit = (val) => {
		return dropdownBenefit.map((benefit) => {
			if (benefit.val == val) return benefit.name;
		});
	};

	return (
		<div className="d-flex flex-column bg-white shadow-sm my-3 p-3 border rounded-lg px-4">
			<h5>Benefit</h5>
			{!benefitData?.length && !benefitKelipatan && !benefitSuperTier ? (
				<div className="bg-light p-3 rounded-lg text-center text-dark">
					<span>
						Silahkan untuk menambahkan filter untuk memunculkan Benefit
					</span>
				</div>
			) : null}
			{benefitData.map((benefit, index) => (
				<BenefitItem
					key={index + 1}
					index={index}
					benefit={benefit}
					handleTypeBenefitChange={handleTypeBenefitChange}
					handleTypeChange={handleTypeChange}
					handleValueChange={handleValueChange}
					handleMaksBenefitChange={handleMaksBenefitChange}
					findBenefit={findBenefit}
					dataProduct={dataProduct}
					setSearchProduct={setSearchProduct}
					handleSelectProduct={handleSelectProduct}
					handleQtyBenefitChange={handleQtyBenefitChange}
					searchProduct={searchProduct}
					stringToArray={stringToArray}
				/>
			))}
			
			{benefitSuperTier && supertier?.is_active ? (
				<BenefitItem
					benefit={{ ...benefitSuperTier, name: 'SUPER TIER' }}
					handleTypeBenefitChange={(i, val) => {
						const type =
							val === 'potongan-harga' ? 'percentage' : 'custom';
						setBenefitSuperTier((prevState) => ({
							...prevState,
							type_benefit: val,
							type,
						}));
					}}
					handleTypeChange={(i, val) => {
						setBenefitSuperTier((prevState) => ({
							...prevState,
							type: val,
							value: '',
						}));
					}}
					handleValueChange={(i, val) => {
						setBenefitSuperTier((prevState) => ({
							...prevState,
							value: val,
						}));
					}}
					handleMaksBenefitChange={(i, val) => {
						setBenefitSuperTier((prevState) => ({
							...prevState,
							maks_benefit: val,
						}));
					}}
					findBenefit={findBenefit}
					dataProduct={dataProduct}
					setSearchProduct={setSearchProduct}
					handleSelectProduct={(i, val) => {
						setBenefitSuperTier((prevState) => ({
							...prevState,
							value: arrayToString(val),
							selectedProduct: val,
						}));
					}}
					handleQtyBenefitChange={(i, val) => {
						setBenefitSuperTier((prevState) => ({
							...prevState,
							benefit: val,
						}));
					}}
					searchProduct={searchProduct}
					stringToArray={stringToArray}
				/>
			) : null}
			{benefitKelipatan ? (
				<BenefitItem
					benefit={{ ...benefitKelipatan, name: '' }}
					handleTypeBenefitChange={(i, val) => {
						const type =
							val === 'potongan-harga' ? 'percentage' : 'custom';
						setBenefitKelipatan((prevState) => ({
							...prevState,
							type_benefit: val,
							type,
						}));
					}}
					handleTypeChange={(i, val) => {
						setBenefitKelipatan((prevState) => ({
							...prevState,
							type: val,
							value: '',
						}));
					}}
					handleValueChange={(i, val) => {
						setBenefitKelipatan((prevState) => ({
							...prevState,
							value: val,
						}));
					}}
					handleMaksBenefitChange={(i, val) => {
						setBenefitKelipatan((prevState) => ({
							...prevState,
							maks_benefit: val,
						}));
					}}
					findBenefit={findBenefit}
					dataProduct={dataProduct}
					setSearchProduct={setSearchProduct}
					handleSelectProduct={(i, val) => {
						setBenefitKelipatan((prevState) => ({
							...prevState,
							value: arrayToString(val),
							selectedProduct: val,
						}));
					}}
					handleQtyBenefitChange={(i, val) => {
						setBenefitKelipatan((prevState) => ({
							...prevState,
							benefit: val,
						}));
					}}
					searchProduct={searchProduct}
					stringToArray={stringToArray}
				/>
			) : null}
		</div>
	);
};

// Benefit items
const BenefitItem = ({
	index,
	benefit,
	handleTypeBenefitChange,
	handleTypeChange,
	handleValueChange,
	handleMaksBenefitChange,
	handleQtyBenefitChange,
	findBenefit,
	dataProduct,
	setSearchProduct,
	handleSelectProduct,
	searchProduct,
	stringToArray,
}) => (
	<div key={index + 1}>
		<div className="row">
			<div className="col-3 mt-2">
				<h6 className="my-auto">Benefit {benefit.name}</h6>
			</div>
			<div className="col-9">
				<SelectList
					placeholder={
						benefit.type_benefit
							? findBenefit(benefit.type_benefit)
							: 'Pilih Jenis Benefit'
					}
					onChange={(e) => handleTypeBenefitChange(index, e.target.value)}
					values={dropdownBenefit}
					value={benefit.type_benefit}
					currValue={
						benefit.type_benefit
							? findBenefit(benefit.type_benefit)
							: null
					}
				/>
			</div>
		</div>

		{benefit.type_benefit === 'potongan-harga' && (
			<PotonganHarga
				index={index}
				type={benefit.type}
				value={benefit.value}
				maksBenefit={benefit.maks_benefit}
				setType={(newType) => handleTypeChange(index, newType)}
				setValue={(newValue) => handleValueChange(index, newValue)}
				setMaksBenefit={(newMaksBenefit) =>
					handleMaksBenefitChange(index, newMaksBenefit)
				}
			/>
		)}

		{benefit.type_benefit === 'free-gift' && (
			<FreeGift
				index={index}
				type={benefit.type}
				value={benefit.value}
				qtyBenefit={benefit.maks_benefit ?? ''}
				setType={(newType) => handleTypeChange(index, newType)}
				setValue={(newValue) => handleValueChange(index, newValue)}
				setQtyBenefit={(newQtyBenefit) =>
					handleMaksBenefitChange(index, newQtyBenefit)
				}
				dataProduct={dataProduct}
				setSearchProduct={setSearchProduct}
				handleSelectProduct={(val) => handleSelectProduct(index, val)}
				searchProduct={searchProduct}
				selectedItems={
					benefit?.selectedProduct?.length ? benefit?.selectedProduct : []
				}
				// selectedItems={stringToArray(benefit.value)}
			/>
		)}

		{/* {benefit.type_benefit === "voucher" && <Voucher />}

    {benefit.type_benefit === "cn" && <CN />} */}

		<hr />
	</div>
);

// Komponen PotonganHarga
const PotonganHarga = ({
	index,
	type,
	setType,
	value,
	setValue,
	maksBenefit,
	setMaksBenefit,
}) => (
	<>
		<div className="row mt-2">
			<div className="col-3" style={{ height: '40px' }}>
				<span
					className="mb-0"
					style={{ fontSize: '12px', fontWeight: '600' }}
				>
					Potongan
				</span>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						marginTop: '0px',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							height: '20px',
						}}
					>
						<InputRadio
							name={`radioPotonganBenefitData-${index}`}
							label="Percent"
							checked={type === 'percentage'}
							onChange={() => setType('percentage')}
						/>
						<InputRadio
							name={`radioPotonganBenefitData-${index}`}
							label="Nominal"
							checked={type === 'amount'}
							onChange={() => setType('amount')}
						/>
					</div>
				</div>
			</div>

			{type === 'percentage' ? (
				<div className="col-9">
					<InputGroup>
						<Input
							type="number"
							placeholder="0"
							min={0}
							max={100}
							value={value}
							onChange={(e) => setValue(e.target.value)}
						/>
						<InputGroupAddon addonType="prepend">
							<InputGroupText>%</InputGroupText>
						</InputGroupAddon>
					</InputGroup>
				</div>
			) : (
				<div className="col-9">
					<InputGroup>
						<InputGroupAddon addonType="append">
							<InputGroupText>Rp</InputGroupText>
						</InputGroupAddon>
						<Input
							type="number"
							placeholder="0"
							min={0}
							value={value}
							onChange={(e) => setValue(e.target.value)}
						/>
					</InputGroup>
				</div>
			)}
		</div>

		{type === 'percentage' && (
			<div className="row mt-2">
				<div className="col-3 mt-2">
					<h6 className="my-auto">Maksimal </h6>
				</div>
				<div className="col-9">
					<InputGroup>
						<InputGroupAddon addonType="append">
							<InputGroupText>Rp</InputGroupText>
						</InputGroupAddon>
						<Input
							type="number"
							placeholder="0"
							min={0}
							value={maksBenefit}
							onChange={(e) => setMaksBenefit(e.target.value)}
						/>
					</InputGroup>
				</div>
			</div>
		)}
	</>
);

// Komponen FreeGift
const FreeGift = ({
	index,
	type,
	setType,
	value,
	setValue,
	qtyBenefit,
	setQtyBenefit,
	dataProduct,
	setSearchProduct,
	handleSelectProduct,
	searchProduct,
	selectedItems,
}) => (
	<div className="row mt-2">
		<div className="col-3" style={{ height: '40px' }}>
			<span className="mb-0" style={{ fontSize: '12px', fontWeight: '600' }}>
				Free Gift
			</span>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					marginTop: '0px',
				}}
			>
				<div
					style={{ display: 'flex', flexDirection: 'row', height: '20px' }}
				>
					<InputRadio
						name={`radioFreeGiftBenefitData-${index}`}
						label="Custom"
						checked={type === 'custom'}
						onChange={() => setType('custom')}
					/>
					<InputRadio
						name={`radioFreeGiftBenefitData-${index}`}
						label="InStock"
						checked={type === 'instock'}
						onChange={() => setType('instock')}
					/>
				</div>
			</div>
		</div>
		<div className="col-9" style={{ height: '40px' }}>
			<div className="d-flex">
				<div style={{ flex: '0 0 78%', marginRight: '6px' }}>
					{type === 'custom' ? (
						<InputText
							placeholder="Masukkan Nama Produk"
							labelWidth={0}
							inputWidth={12}
							value={value}
							onChange={(e) => setValue(e.target.value)}
						/>
					) : (
						<SearchableDropdown
							title="SKU"
							items={dataProduct}
							selectedItems={selectedItems}
							setSelectedItems={handleSelectProduct}
							searchTerm={searchProduct}
							setSearchTerm={setSearchProduct}
							// showSelected
						/>
					)}
				</div>
				<div>
					<InputGroup className="w-30">
						<InputGroupAddon addonType="prepend">
							<InputGroupText>Qty</InputGroupText>
						</InputGroupAddon>
						<Input
							type="number"
							placeholder="0"
							min={0}
							value={qtyBenefit}
							onChange={(e) => setQtyBenefit(e.target.value)}
						/>
					</InputGroup>
				</div>
			</div>
		</div>
	</div>
);

// Komponen Voucher
const Voucher = () => (
	<div className="row mt-2">
		<div className="col-3">
			<h6 className="my-auto">Voucher</h6>
		</div>
		<div className="col-9">
			<SelectList placeholder="Pilih Voucher" values={[]} />
		</div>
	</div>
);

// Komponen CN
const CN = () => (
	<div className="row mt-2">
		<div className="col-3">
			<h6 className="my-auto">CN</h6>
		</div>
		<div className="col-9">
			<InputGroup className="w-30">
				<Input type="number" placeholder="0" min={0} max={100} />
				<InputGroupAddon addonType="prepend">
					<InputGroupText>%</InputGroupText>
				</InputGroupAddon>
			</InputGroup>
		</div>
	</div>
);

const dropdownBenefit = [
	{ name: 'Potongan Harga', val: 'potongan-harga' },
	{ name: 'Free Gift', val: 'free-gift' },
	{ name: 'Voucher', val: 'voucher' },
	{ name: 'CN', val: 'cn' },
];

export default BenefitPromo;
