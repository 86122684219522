import { Fragment, useEffect, useMemo, useState } from "react";
import Breadcrumb from "@layout/breadcrumb";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getListCategory } from "@action/categoryAction";
import { getListBrand } from "@action/brandAction";
import { addDataProduct } from "@action/productAction";

import {
  FormWrapper,
  InputText,
  InputNumber,
  InputSelectSearch,
  InputArea,
} from "@components/formComponent";
import VideoUploader from "@components/videoUploader";

const ProductAdd = () => {
  let timeoutId;
  const history = useHistory();
  const dispatch = useDispatch();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [formState, setFormsState] = useState({
    name: "",
    category_id: "",
    brand_id: "",
    weight: 0,
    sku_code: "",
    max_qty: 0,
    video_url: "",
    description: "",
    video: null,
  });

  const listCategory = useSelector(
    (state) => state.CategoryReducer.listCategory
  );
  const listBrand = useSelector((state) => state.BrandReducer.listBrand);

  useEffect(() => {
    dispatch(getListCategory(10, 1, ""));
    dispatch(getListBrand(10, 1, ""));

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const memoizedCategoryData = useMemo(
    () => listCategory.data,
    [listCategory.data]
  );
  const memoizedBrandData = useMemo(() => listBrand.data, [listBrand.data]);

  useEffect(() => {
    if (memoizedCategoryData) {
      const catData = listCategory.data.data;

      let mapCategory = catData.map((item) => ({
        id: item?.id,
        value: item?.name,
      }));

      setCategoryList(mapCategory);
    }
  }, [memoizedCategoryData]);

  useEffect(() => {
    if (memoizedBrandData) {
      const brandData = listBrand.data.data;

      let mapBrand = brandData.map((item) => ({
        id: item?.id,
        value: item?.name,
      }));

      setBrandList(mapBrand);
    }
  }, [memoizedBrandData]);

  const handleSearchCategory = (param) => {
    dispatch(getListCategory(10, 1, param));
  };

  const handleSearchBrand = (param) => {
    dispatch(getListBrand(10, 1, param));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmit(true);

    const dataForm = new FormData();
    for (let key in formState) {
      dataForm.append(key, formState[key]);
    }
    
    dispatch(addDataProduct(dataForm))
      .then(() => {
        clearTimeout(timeoutId);
        history.push("/dashboard/product");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        timeoutId = setTimeout(() => {
          setDisableSubmit(false);
        }, 800);
      });
  };

  const handleFileSelect = (file) => {
    setFormsState((prevState) => ({
      ...prevState,
      video: file,
    }));
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Product"
        title="Add New Product"
        urlParent={"/dashboard/product"}
      />
      <Container fluid>
        <Row>
          <Col className="p-2" xl={8}>
            <FormWrapper
              onSubmit={handleSubmit}
              disableSubmit={disableSubmit}
              onCancel={() => history.push("/dashboard/product")}
            >
              <InputText
                label="Product Name"
                value={formState.name}
                onChange={(e) => {
                  setFormsState((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }));
                }}
              />
              <InputSelectSearch
                label="Category"
                options={categoryList}
                onInputChange={handleSearchCategory}
                onSelectChange={(id) => {
                  setFormsState((prevState) => ({
                    ...prevState,
                    category_id: id,
                  }));
                }}
              />
              <InputSelectSearch
                label="Brand"
                options={brandList}
                onInputChange={handleSearchBrand}
                onSelectChange={(id) => {
                  setFormsState((prevState) => ({
                    ...prevState,
                    brand_id: id,
                  }));
                }}
              />
              <InputNumber
                label="Weight"
                value={formState.weight}
                addOnText="gram"
                onChange={(e) => {
                  setFormsState((prevState) => ({
                    ...prevState,
                    weight: e.target.value,
                  }));
                }}
              />
              <InputText
                label="SKU Code"
                value={formState.sku_code}
                onChange={(e) => {
                  setFormsState((prevState) => ({
                    ...prevState,
                    sku_code: e.target.value,
                  }));
                }}
              />
              <InputNumber
                label="Max QTY"
                value={formState.max_qty}
                onChange={(e) => {
                  setFormsState((prevState) => ({
                    ...prevState,
                    max_qty: e.target.value,
                  }));
                }}
                addOnText="pcs"
              />
              <InputText
                label="Video URL"
                value={formState.video_url}
                onChange={(e) => {
                  setFormsState((prevState) => ({
                    ...prevState,
                    video_url: e.target.value,
                  }));
                }}
              />
              <VideoUploader
                label={"Video Product"}
                onFileSelect={handleFileSelect}
              />
              <InputArea
                label="Description"
                value={formState.description}
                onChange={(e) => {
                  setFormsState((prevState) => ({
                    ...prevState,
                    description: e.target.value,
                  }));
                }}
              />
            </FormWrapper>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ProductAdd;
