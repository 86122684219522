import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";
import iconSearch from "@assets/images/input-search.png";
import userDefault from "@assets/images/User.jpg";

const SearchableDropdown = ({
  items,
  selectedItems,
  setSelectedItems,
  searchTerm,
  setSearchTerm,
  title = "",
  showSelected,
  disabled,
}) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  // Toggle the dropdown open/close
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle checkbox toggle
  const handleCheckboxChange = (item) => {
    if (selectedItems?.find((selectedItem) => selectedItem.id === item.id)) {
      // Uncheck item
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem.id !== item.id)
      );
    } else {
      // Check item
      setSelectedItems([...selectedItems, item]);
    }
  };

  // Handle remove selected item
  const handleRemoveSelectedItem = (itemId) => {
    setSelectedItems(
      selectedItems.filter((selectedItem) => selectedItem.id !== itemId)
    );
  };

  // Handle select/unselect all
  const handleSelectAll = () => {
    if (selectedItems.length === items.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(items);
    }
  };

  // Filter items based on the search term
  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Get the selected items to display in the dropdown toggle
  const selectedDisplayItems = selectedItems;

  return (
    <Dropdown className="w-100" lg={10} isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        disabled={disabled}
        color=""
        className="w-100 h-100 d-flex justify-content-between align-items-center"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          paddingLeft: '16px'
        }}
        caret
      >
        {!showSelected ? `Selected ${selectedDisplayItems.length} Items` : selectedDisplayItems.length > 0 ? (
          <div
            className="d-flex align-items-center flex-wrap"
            style={{ maxHeight: "200px", overflowY: "auto" }}
          >
            {selectedDisplayItems.map((item) => (
              <div
                key={item.id}
                className="d-flex align-items-center justify-content-between"
                style={{
                  backgroundColor: "#F0F2F5",
                  borderRadius: "12px",
                  padding: "2px 8px",
                  margin: "2px",
                  maxWidth: "100%",
                  overflow: "hidden",
                }}
              >
                <span
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "150px",
                    color: "#092540",
                  }}
                >
                  {item.name}
                </span>
                <span
                  onClick={() => handleRemoveSelectedItem(item.id)}
                  style={{
                    marginLeft: "8px",
                    cursor: "pointer",
                    color: "#687083",
                  }}
                >
                  &times;
                </span>
              </div>
            ))}
          </div>
        ) : (
          `Pilih ${title}`
        )}
      </DropdownToggle>
      <DropdownMenu
        style={{
          width: "100%",
          backgroundColor: "#fff",
          maxHeight: "300px",
          overflowY: "auto",
        }}
      >
        {/* Search Box and Select All Button */}
        <div className="d-flex align-items-center mx-3 my-2">
          <div
            style={{ cursor: "pointer" }}
            // onClick={handleSelectAll}
          >
            {/* {selectedItems.length === items.length ? ( */}
            <SelectAllIcon />
            {/* ) : (
              <SelectAllIcon
                style={{
                  backgroundColor: "white",
                  color: "gray",
                  border: "2px solid gray",
                }}
              />
            )} */}
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginLeft: "15px",
            }}
          >
            <img
              src={iconSearch}
              alt="Search"
              width={18}
              style={{
                position: "absolute",
                left: "10px",
                filter: "invert(50%) sepia(0%) saturate(0%) brightness(50%)",
              }}
            />
            <Input
              type="text"
              placeholder={`Search ${title}`}
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                paddingLeft: "35px",
                width: "95%",
              }}
            />
          </div>
        </div>

        {/* Dropdown Items with Checkboxes */}
        {!filteredItems.length && <span className="ml-4">No Data</span>}
        {filteredItems.map((item) => (
          <DropdownItem
            key={item.id}
            toggle={false}
            className="mx-3"
            style={{ width: "87%", borderRadius: "4px" }}
            // onClick={() => handleCheckboxChange(item)}
          >
            <label
              className="d-flex align-items-center"
              style={{ width: "100%" }}
            >
              <Input
                type="checkbox"
                style={{ transform: "scale(1.3)" }}
                checked={selectedItems?.some(
                  (selectedItem) => selectedItem.id === item.id
                )}
                onChange={() => handleCheckboxChange(item)}
              />
              <img
                src={item.image ?? userDefault}
                alt={item.name}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              />
              {item.phoneNumber ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0",
                  }}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "100%",
                    }}
                  >
                    {item.name}
                  </span>
                  <span
                    style={{
                      fontSize: "10px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "100%",
                    }}
                  >
                    {item.phoneNumber}
                  </span>
                </div>
              ) : (
                <span
                  style={{
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "calc(100% - 50px)",
                  }}
                >
                  {item.name}
                </span>
              )}
            </label>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const SelectAllIcon = ({ style = {} }) => {
  return (
    <div
      style={{
        width: "16.5px",
        height: "16.5px",
        marginLeft: "1.5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "2px",
        borderRadius: "4px",
        backgroundColor: "blue",
        color: "white",
        border: "2px solid blue",
        ...style,
      }}
    >
      -
    </div>
  );
};

export default SearchableDropdown;
