import apiConfig from "../../api";
import {
  GET_LIST_PRODUCT,
  GET_DETAIL_PRODUCT,
  GET_SYNC_PRODUCT,
  WATCH_RESPONSE_ALERT,
  DOWNLOAD_PRODUCT,
  UPLOAD_MASS_PRODUCT,
  BULK_PRODUCT,
  GET_LIST_LOG,
  GET_LIST_PRICE,
  GET_LIST_PRODUCT_DISCOUNT,
} from "../actionTypes";

export const getListProduct = (page, param, perPage) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/products?page=${page}&search=${param}${
          perPage ? `&perPage=${perPage}` : "&perPage=10"
        }`
      );
      dispatch({
        type: GET_LIST_PRODUCT,
        payload: {
          loading: false,
          data: resp.data,
          err: false,
        },
      });

	  return resp
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: err.message,
        },
      });
    }
  };
};

export const getListProductLogs = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_LOG,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/products/logs/${id}`)
      .then((resp) => {
        dispatch({
          type: GET_LIST_LOG,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_LOG,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const addDataProduct = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(`/api/admin/products`, payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });
      return resp;
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });
      throw err;
    }
  };
};

export const updateDataProduct = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(`/api/admin/products/update`, payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });
      return resp;
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });
      throw err;
    }
  };
};

export const getDetailProduct = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_DETAIL_PRODUCT,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/products/${id}`)
      .then((resp) => {
        dispatch({
          type: GET_DETAIL_PRODUCT,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_DETAIL_PRODUCT,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const getSyncProduct = () => {
  return (dispatch) => {
    dispatch({
      type: GET_SYNC_PRODUCT,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/products/sync`)
      .then((resp) => {
        console.log("res", resp);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const downloadProduct = (type) => {
  return (dispatch) => {
    dispatch({
      type: DOWNLOAD_PRODUCT,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    let url = type === "all" ? "/" : `/${type}/`;
    let name = type === "all" ? "download-product" : `download-product-${type}`;

    apiConfig
      .get(`/api/admin/products${url}download`, { responseType: "blob" })
      .then((resp) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(resp.data);
        link.setAttribute("download", `${name}.xlsx`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        document.body.removeChild(link);

        dispatch({
          type: DOWNLOAD_PRODUCT,
          payload: {
            loading: false,
            data: URL.createObjectURL(resp.data),
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: DOWNLOAD_PRODUCT,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const uploadMassProduct = (type, payload) => {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_MASS_PRODUCT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post(`/api/admin/products/mass/upload-${type}`, payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const bulkProduct = (payload) => {
  return (dispatch) => {
    dispatch({
      type: BULK_PRODUCT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post(`/api/admin/products/bulk-action`, payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const uploadProductImage = (id, payload) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post(`/api/admin/products/upload/${id}`, payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const getProductPriceList = (id, page, perPage) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_PRICE,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/products/prices/${id}?page=${page}&perPage=${perPage}`)
      .then((resp) => {
        dispatch({
          type: GET_LIST_PRICE,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_PRICE,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const getListProductDiscount = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_PRODUCT_DISCOUNT,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/products/discount/${id}`)
      .then((resp) => {
        dispatch({
          type: GET_LIST_PRODUCT_DISCOUNT,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_PRODUCT_DISCOUNT,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const addDataProductDiscount = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(
        `/api/admin/products/discount`,
        payload
      );
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });
      return resp;
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });
      throw err;
    }
  };
};

export const updateDataProductDiscount = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.put(`/api/admin/products/discount`, payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });
      return resp;
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      throw err;
    }
  };
};

export const deleteDataProductDiscount = (id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.delete(`/api/admin/products/discount/${id}`);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });
    }
  };
};
